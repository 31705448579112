import Vue from '../vue-config'
import Tab from '../components/utils/tab.vue';
import Tabs from '../components/utils/tabs.vue';
import RecentTrades from '../components/private-market/recent-trades.vue';
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
import debuggerInit from 'highcharts/modules/debugger';
import { format, parseISO } from 'date-fns';
import Auction from '../components/security-details/auction.vue';

if (document.getElementById('private-market-vm')) {

    var vm = new Vue({
        el: '#private-market-vm',
        components: {
            Tab,
            Tabs,
            RecentTrades,
            Chart,
            Auction
        },
        data: {
            orders: [],
            tradePrices: null,
            // prices: null,
            auction: null,
        },
        computed: {
            currency() {
                return (this.orders.length && this.orders[0].price) > 0 ? this.orders[0].price.currency : ''
            },
            hasTradePrices() {
                return this.tradePrices && this.tradePrices.length > 0;
            },
            chartOptions() {
                if (!this.hasTradePrices) return null;

                return {
                    rangeSelector: {
                        selected: 5,
                        labelStyle: {
                            color: this.brandcolor
                        },
                        inputStyle: {
                            color: this.brandcolor
                        }
                    },
                    navigator: {
                        maskFill: this.textcolor + "33"
                    },
                    xAxis: {
                        type: 'datetime',
                        minRange: 24 * 3600 * 1000,
                        ordinal: false,
                        labels: {
                            style: {
                                color: this.textcolor
                            }
                        }
                    },
                    yAxis: {
                        labels: {
                            style: {
                                color: this.textcolor
                            }
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    series: [
                        {
                            id: "trades",
                            name: "trades",
                            data: this.tradePrices,
                            color: this.brandcolor,
                            tooltip: {
                                valueDecimals: 2
                            },
                            dataLabels: {
                                color: this.brandcolor,
                            }
                        },
                    ],
                };
            }
        },
        watch: {
        },
        methods: {
            getAuctionData() {
                var vm = this
                // fetch('/umbraco/surface/privatemarket/getdata', {
                fetch('/api/private-market/auction', {
                    method: 'POST',
                    body: JSON.stringify({ url: pricesUrl }),
                    headers: { 'Content-Type': 'application/json' }
                }).then((response) => {
                    response.json().then((data) => {
                        vm.auction = data;
                    })
                })
            },
            getOrders() {
                var vm = this
                // fetch('/umbraco/surface/privatemarket/getdata', {
                fetch('/api/private-market/trades', {
                    method: 'POST',
                    body: JSON.stringify({ url: ordersUrl }),
                    headers: { 'Content-Type': 'application/json' }
                }).then((response) => {
                    response.json().then((data) => {
                        //vm.orders = JSON.parse(data)
                        vm.orders = data;
                    })
                })
            },
            getChartData() {
                const tradesPromise = fetch('/api/private-market/chart', {
                    method: 'POST',
                    body: JSON.stringify({ url: ordersUrl }),
                    headers: { 'Content-Type': 'application/json' }
                });

                tradesPromise.then((chartData) => {

                    chartData.json().then((data) => {

                        this.tradePrices = data.map((t) => {
                            return [
                                t.date ? parseInt(format(parseISO(t.date), "T")) : null,
                                t.price
                            ];
                        }).sort((a, b) => {return a[0] - b[0]; });
                    });
                });
            }
            // getPrices() {
            //     var vm = this
            //     fetch('/umbraco/surface/privatemarket/getdata', {
            //         method: 'POST',
            //         body: JSON.stringify({ url: pricesUrl }),
            //         headers: { 'Content-Type': 'application/json' }
            //     }).then((response) => {
            //         response.json().then((data) => {
            //             vm.prices = JSON.parse(data)
            //         })
            //     })
            // }
        },
        mounted() {
            this.getOrders();
            this.getChartData();
            this.getAuctionData();
            stockInit(Highcharts);
            debuggerInit(Highcharts);

            this.brandcolor = this.$el.dataset.brandcolor;
            this.textcolor = this.$el.dataset.textcolor;
        }
    })
}