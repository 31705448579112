var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "market-update market-update-closing-prices" },
    [
      _vm.hasData
        ? _c("table", { staticClass: "data closing-prices-table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm._l(
                  Object.keys(_vm.closingPrices),
                  function (priceKey, index) {
                    return [
                      _c(
                        "tr",
                        { key: "st" + index, staticClass: "table-body-header" },
                        [
                          _c("th", { attrs: { colspan: "99" } }, [
                            _vm._v(
                              _vm._s(_vm.SecurityTypeDescription(priceKey))
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.closingPrices[priceKey],
                        function (price, priceIndex) {
                          return [
                            _c("tr", { key: "st" + index + "p" + priceIndex }, [
                              _c("td", [
                                _c(
                                  "a",
                                  { attrs: { href: _vm.SecurityUrl(price) } },
                                  [_vm._v(_vm._s(price.CompanyName))]
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                    - " +
                                    _vm._s(price.ClassName) +
                                    " (" +
                                    _vm._s(price.Mnemonic) +
                                    ")"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(price.CurrencyCode))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(price.CloseBid))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(price.CloseAsk))]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    price.YearToDateVolume > 0
                                      ? price.YearToDateVolume
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        }
                      ),
                    ]
                  }
                ),
              ],
              2
            ),
          ])
        : _c("div", { staticClass: "loading-spinner" }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v("Company Name"),
          _c("br"),
          _vm._v("\n                - Class Name (Mnemonic)"),
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("Currency")]),
        _vm._v(" "),
        _c("th", [_vm._v("Bid")]),
        _vm._v(" "),
        _c("th", [_vm._v("Ask")]),
        _vm._v(" "),
        _c("th", [_vm._v("YTD Vol")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }