var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.security && _vm.loadedTradePrices
    ? _c(
        "div",
        { staticClass: "security-details" },
        [
          _c(
            "tabs",
            { ref: "tabs" },
            [
              _vm.auction
                ? _c(
                    "tab",
                    { attrs: { title: "Auction System" } },
                    [
                      _c("Auction", {
                        attrs: {
                          theme: _vm.theme,
                          auction: _vm.auction,
                          currency: _vm.security.CurrencyCode,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.security.WillTrade && _vm.hasTradePrices
                ? _c(
                    "tab",
                    {
                      attrs: {
                        enabled: _vm.security.WillTrade && _vm.hasTradePrices,
                        title: "Trades & Stats",
                      },
                    },
                    [
                      _vm.chartOptions
                        ? _c("chart", {
                            ref: "highChart",
                            attrs: {
                              "constructor-type": "stockChart",
                              options: _vm.chartOptions,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("RecentTrades", {
                        attrs: {
                          securityClassId: _vm.security.SecurityClassId,
                          currencyCode: _vm.security.CurrencyCode,
                        },
                      }),
                      _vm._v(" "),
                      _c("StatsData", {
                        attrs: { security: _vm.security, stats: _vm.stats },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.security.HasNav && _vm.loadedTradePrices
                ? _c(
                    "tab",
                    { attrs: { enabled: _vm.security.HasNav, title: "NAVs" } },
                    [
                      _c("Navs", {
                        attrs: {
                          securityClassId: _vm.security.SecurityClassId,
                          companyId: _vm.security.CompanyId,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "security-details-section" },
                        [
                          _vm.security.WillTrade &&
                          _vm.loadedTradePrices &&
                          !_vm.hasTradePrices
                            ? _c("StatsData", {
                                attrs: {
                                  security: _vm.security,
                                  stats: _vm.stats,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "tab",
                { attrs: { title: "News" } },
                [
                  _vm.security && _vm.security.CompanyId
                    ? _c("News", {
                        attrs: { companyId: _vm.security.CompanyId },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "tab",
                {
                  attrs: {
                    enabled:
                      _vm.security.ReutersPage &&
                      _vm.security.ReutersPage.length > 0,
                    title: "Information",
                  },
                },
                [_c("pre", [_vm._v(_vm._s(_vm.security.ReutersPage))])]
              ),
              _vm._v(" "),
              _vm.documents
                ? _c(
                    "tab",
                    { attrs: { title: "Documents" } },
                    [
                      _c("Documents", {
                        attrs: { theme: _vm.theme, documents: _vm.documents },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Modal",
            {
              attrs: { open: _vm.modalOpen },
              on: {
                onClose: function ($event) {
                  _vm.modalOpen = false
                },
              },
            },
            [
              _vm.modalContent
                ? _c("div", {
                    staticClass: "modal__window__content",
                    domProps: { innerHTML: _vm._s(_vm.modalContent) },
                  })
                : _c("div", { staticClass: "modal__window__content loading" }, [
                    _c("div", { staticClass: "loading-spinner" }),
                  ]),
            ]
          ),
        ],
        1
      )
    : _c("div", { staticClass: "skeleton" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "tabs" }, [
      _c("li", { staticClass: "active" }),
      _vm._v(" "),
      _c("li", {}),
      _vm._v(" "),
      _c("li", {}),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab" }, [
      _c("p", { staticClass: "img" }),
      _vm._v(" "),
      _c("p", { staticClass: "long" }),
      _vm._v(" "),
      _c("p", { staticClass: "long" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }