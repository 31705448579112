var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "article market-news" }, [
    _c("div", { staticClass: "article-meta" }, [
      _c("div", { staticClass: "article-meta-container" }, [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _vm.description
          ? _c("p", {}, [_vm._v(_vm._s(_vm.description))])
          : _vm._e(),
        _vm._v(" "),
        _vm.renderFilter
          ? _c(
              "form",
              { key: _vm.renderFilterKey },
              [
                _c("div", { staticClass: "form-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchTerm,
                        expression: "searchTerm",
                      },
                    ],
                    staticClass: "has-icon",
                    attrs: { placeholder: "Company name", name: "search-term" },
                    domProps: { value: _vm.searchTerm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchTerm = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("button", { on: { click: _vm.Search } }, [
                    _c("i", { staticClass: "fas fa-search" }),
                  ]),
                ]),
                _vm._v(" "),
                _c("Toggle", {
                  attrs: {
                    showLabels: "",
                    labelChecked: "NAVs are included",
                    labelUnchecked: "NAVs are excluded",
                  },
                  model: {
                    value: _vm.includeNavs,
                    callback: function ($$v) {
                      _vm.includeNavs = $$v
                    },
                    expression: "includeNavs",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "article-content" },
      [
        _c("MarketNewsDataList", {
          attrs: {
            theme: _vm.theme,
            pageNo: _vm.pageNo,
            news: _vm.news,
            totalNews: _vm.totalNews,
            pages: _vm.pages,
          },
          on: { changePage: _vm.UpdatePage },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }