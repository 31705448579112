var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.closingPrices
    ? _c("div", { staticClass: "price-ticker" }, [
        _c("div", { staticClass: "price-ticker-heading" }, [
          _c("a", { attrs: { href: _vm.link } }, [_vm._v(_vm._s(_vm.title))]),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "price-ticker-list list-unstyled list-inline" },
          _vm._l(_vm.closingPrices, function (item, index) {
            return _c(
              "li",
              {
                key: item.TradeSecurityId,
                staticClass: "price-ticker-list-item",
                class: `TradeSecurityId-${item.TradeSecurityId}`,
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "valign-middle",
                    attrs: {
                      href: `/market/securities/${item.SecurityClassId}`,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "price-ticker-list-item-heading" },
                      [
                        _vm._v(
                          _vm._s(item.CompanyName) +
                            " " +
                            _vm._s(item.ClassName)
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "price-ticker-list-item-values" },
                      [
                        _vm._v(
                          _vm._s(item.CurrencyCode) +
                            " Bid:" +
                            _vm._s(item.CloseBid) +
                            " - Ask:" +
                            _vm._s(item.CloseAsk)
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }