import Vue from 'vue';
import { Buffer } from 'buffer';

Vue.config.silent = false
Vue.config.devtools = true

Vue.directive('clickout', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})


Vue.directive('tracked', {
    bind: function (el, binding, vnode) {
        el.cursorEvent = function (e) {
            var r = el.getBoundingClientRect()
            var x = e.touches ? e.touches[0].clientX - r.left : e.clientX - r.left
            var y = e.touches ? e.touches[0].clientY - r.top : e.clientY - r.top
            el.style.setProperty('--x', `${x}px`)
            el.style.setProperty('--y', `${y}px`)
        }
        el.addEventListener('mousemove', el.cursorEvent)
        el.addEventListener('touchmove', el.cursorEvent)
        el.classList.add('vtracked')
    },
    unbind: function (el) {
        el.removeEventListener('mousemove', el.cursorEvent)
        el.removeEventListener('touchmove', el.cursorEvent)
    }
})

Vue.mixin({
    methods: {
        getHash() {
            var hash = window.location.hash.replace('#', '')
            return atob(window.decodeURIComponent(hash))
        },
        setHash(value, replaceState) {
            if (replaceState === undefined) replaceState = false;

            var baseUrl = [location.protocol, '//', location.host, location.pathname].join('')
            var hash = window.encodeURIComponent(btoa(value))

            if (replaceState) {
                window.history.replaceState({}, "", baseUrl + '#' + hash)
            } else {
                window.history.pushState({}, "", baseUrl + '#' + hash)
            }
        },

    }
});

export default Vue
