<template>
    <div v-if="closingPrices" class="price-ticker">
        <div class="price-ticker-heading">
            <a :href="link">{{title}}</a>
        </div>
        <ul class="price-ticker-list list-unstyled list-inline">
            <li class="price-ticker-list-item" :class="`TradeSecurityId-${item.TradeSecurityId}`" v-for="(item, index) in closingPrices" :key="item.TradeSecurityId">
                <a :href="`/market/securities/${item.SecurityClassId}`" class="valign-middle">
                    <div class="price-ticker-list-item-heading">{{item.CompanyName}} {{item.ClassName}}</div>
                    <div class="price-ticker-list-item-values">{{item.CurrencyCode}} Bid:{{item.CloseBid}} - Ask:{{item.CloseAsk}}</div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            theme: String,
            title: String,
            link: String
        },
        components: {

        },
        data() {
             return {
                 closingPricesUrl: '/umbraco/surface/ProxyApi/ClosingPrices',
                 closingPrices: null,
                 totalclosingPrices: 0,
                 animateHandle: null,
                 animateInterval: 3000,
                 animationStep: 0
             };
        },
        computed: {

        },
        methods: {
            LoadData() {
                const closingPricesPromise = fetch(this.closingPricesUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                closingPricesPromise.
                    then(response => response.json()).
                    then((data) => {
                        this.closingPrices = data;
                        this.totalclosingPrices = data.length;

                        this.Animate();
                });

            },
            AnimateStep() {
                if (this.closingPrices) {
                        // move the first element to the back
                        let items = this.closingPrices.slice(0);
                        let firstItem = items.shift();
                        items.push(firstItem);
                        this.closingPrices = items;

                        // reload once all items animated in
                        this.animationStep = this.animationStep + 1;
                        //console.log(this.animationStep + "/" + this.totalclosingPrices)
                        if (this.animationStep >= this.totalclosingPrices) {
                            this.RemoveAnimate();
                            this.LoadData();
                        }
                    }
            },
            Animate() {
                // animate
                this.animateHandle = setInterval(() => {
                    this.AnimateStep();
                }, this.animateInterval);
            },
            RemoveAnimate() {
                if (this.animateHandle) {
                    clearInterval(this.animateHandle);
                    this.animateHandle = null;
                    this.animationStep = 0;
                }
            }
        },
        beforeMount() {
            this.LoadData();
        },
        beforeUnmount() {
            this.RemoveAnimate();
        }
    };
</script>