<template>
    <div class="sustainable-securities table-scrollable-container">

        <table v-if="securities" class="data sustainable-securities-table">
            <thead>
                <tr>
                    <th>Issuer</th>
                    <th>ISIN</th>
                    <th>Listing Date</th>
                    <th>Nominal</th>
                    <th>Currency</th>
                    <th>Credentials</th>
                    <th>Information</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in securities" :key="index">
                    <td class="nowrap-xl"><a :href="SecurityUrl(item)">{{item.CompanyName}}</a></td>
                    <td>{{item.ISIN}}</td>
                    <td class="nowrap">{{item.ListingDate}}</td>
                    <td>{{Number(item.Nominal).toLocaleString()}}</td>
                    <td>{{item.CurrencyCode}}</td>
                    <td><div v-for="(cred, credIndex) in item.Credentials" :key="credIndex">{{ cred }}</div></td>
                    <td><a :href="item.Url">Link</a></td>
                </tr>
            </tbody>
        </table>

        <div v-else class="loading-spinner"></div>
    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    export default {
        props: {
            theme: String
        },
        components: {
        },
        data() {
             return {
                 securities: null,
                 securitiesUrl: '/umbraco/surface/ProxyApi/SustainableSecurities'
             };
        },
        computed: {
        },
        methods: {
            SecurityUrl(item) {
                return `/market/securities/${item.Id}`;
            },
            LoadData() {

                const securitiesPromise = fetch(this.securitiesUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                securitiesPromise.
                    then(response => response.json()).
                    then((data) => {
                        this.securities = data.map((x) => {
                            return Object.assign(x, {
                                ListingDate: x.ListingDate ? format(parseISO(x.ListingDate), "dd-MM-yyyy") : null,
                            });
                        });

                });

            },
        },
        beforeMount() {
            this.LoadData();
        }
    };
</script>