var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "market-update market-update-recent-trades" },
    [
      _vm.hasRecentTrades
        ? _c("table", { staticClass: "data security-trades-table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.recentTrades, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", [
                    _c("a", { attrs: { href: _vm.SecurityUrl(item) } }, [
                      _vm._v(_vm._s(item.Mnemonic)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.Price))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.Size))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.CurrencyCode))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.TradeDate))]),
                ])
              }),
              0
            ),
          ])
        : _c("div", { staticClass: "loading-spinner" }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Mnemonic")]),
        _vm._v(" "),
        _c("th", [_vm._v("Price")]),
        _vm._v(" "),
        _c("th", [_vm._v("Size")]),
        _vm._v(" "),
        _c("th", [_vm._v("Currency")]),
        _vm._v(" "),
        _c("th", [_vm._v("Time/Date")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }