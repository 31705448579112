var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "data-cell-panel" }, [
    _vm.title ? _c("h4", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "data-cells" },
      _vm._l(_vm.cells, function (cell, index) {
        return _c("DataCell", {
          key: index,
          attrs: {
            title: cell.title,
            value: cell.value ? new String(cell.value) : "",
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }