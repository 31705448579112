var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "members-data-list" }, [
    _c("div", { staticClass: "data-list" }, [
      _c(
        "div",
        { staticClass: "data-list-header" },
        [
          _vm._t("header", function () {
            return [
              _c("output", { attrs: { form: "members-search-filter" } }, [
                _vm._v(_vm._s(_vm.totalMembers) + " results"),
              ]),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "data-list-body" },
        _vm._l(_vm.members, function (item) {
          return _c("div", { key: item.Id, staticClass: "data-list-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "data-list-item-field data-list-item-col members-name",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "members-company",
                    attrs: { href: _vm.MemberItemUrl(item.CompanyId) },
                  },
                  [_vm._v(_vm._s(item.CompanyName))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "members-type" }, [
                  _vm._v(_vm._s(item.ListingDescription)),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "data-list-item-field members-categories" },
              [
                _c("span", { staticClass: "members-categories-label" }, [
                  _vm._v("Category "),
                ]),
                _vm._v(
                  _vm._s(_vm.ItemListingCategories(item)) + "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "data-list-item-field members-jurasdiction" },
              [_vm._v(_vm._s(item.Country))]
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-list-header" }, [
      _c("div", { staticClass: "data-list-item" }, [
        _c("div", { staticClass: "data-list-item-field" }, [
          _vm._v("Type & Name"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "data-list-item-field members-categories" }, [
          _vm._v("Categories"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "data-list-item-field" }, [
          _vm._v("Jurisdiction"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }