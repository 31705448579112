<template>
    <div class="securities sustainable-securities-embed">
        <tabs ref="tabs" class="full-width">
            <tab title="Securities">
                <p>A list of securities admitted to TISE Sustainable.</p>
                <SustainableSecurities />
            </tab>
            <tab title="Issuers">
                <p>A list of issuers admitted to TISE Sustainable.</p>
                <SustainableIssuers />
            </tab>
        </tabs>
    </div>
</template>

<script>
    import Tab from '../components/utils/tab.vue';
    import Tabs from '../components/utils/tabs.vue';
    import SustainableSecurities from '../components/sustainable-embed/sustainable-securities.vue';
    import SustainableIssuers from '../components/sustainable-embed/sustainable-issuers.vue';

    export default {
        props: {
            theme: {
                type: String,
                required: false,
                default: "green"
            },
            filter: {
                type: Object,
                required: false,
                default: {}
            }
        },
        components: {
            Tabs,
            Tab,
            SustainableSecurities,
            SustainableIssuers
        },
        data() {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
        beforeMount() {

        }
    };
</script>