var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.open,
          expression: "open",
        },
      ],
      staticClass: "modal",
      class: _vm.open ? " open" : "",
    },
    [
      _c(
        "div",
        { staticClass: "modal__window" },
        [
          _c("div", { staticClass: "modal__close", on: { click: _vm.Close } }),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }