<template>
    <form class="securities-search-filter" id="securities-search-filter">
        <div class="form-field">
            <label for="search-term">Search & Filter</label>
            <div class="input-group">
                <input class="has-icon" placeholder="Company, mnemonic or ISIN" name="search-term" @keyup.enter="UpdateSearch" v-model="searchTermInput" />
                <button @click="UpdateSearch"><i class="fas fa-search"></i></button>
            </div>
        </div>
        <div class="form-field">
            <label for="security-type">Security Type</label>
            <SelectField name="security-type" :initValue="0" v-model="selectedSecurityType" :values="SecurityTypes" v-on:input="UpdateSearch" />
        </div>
        <div class="form-field">
            <label for="domicile">Domicile</label>
            <SelectField name="domicile" :initValue="0" v-model="selectedDomicile" :values="Domiciles" v-on:input="UpdateSearch" />
        </div>
        <div class="form-field">
            <label for="currency">Currency</label>
            <SelectField name="currency" :initValue="0" v-model="selectedCurrency" :values="Currencies" v-on:input="UpdateSearch" />
        </div>
    </form>
</template>

<script>
    import SelectField from './utils/select-field.vue';

    export default {
        props: {
            searchTerm: {
                type: String
            },
            securityType: {
                type: Number
            },
            domicile: {
                type: Number
            },
            currency: {
                type: Number
            },
        },
        components: {
            SelectField
        },
        data() {
            return {
                domicilesUrl: '/umbraco/surface/ProxyApi/Countries',
                securityTypesUrl: '/umbraco/surface/ProxyApi/securityTypes',
                currenciesUrl: '/umbraco/surface/ProxyApi/currencies',
                domiciles: [],
                securityTypes: [],
                currencies: [],
                selectedSecurityType: this.securityType,
                selectedDomicile: this.domicile,
                selectedCurrency: this.currency,
                searchTermInput: ''
            };
        },
        computed: {
            SecurityTypes() {
                return this.MapDataToSelect('securityTypes', 'Id', 'Description', 'All Security Types', 0);
            },
            Domiciles() {
                return this.MapDataToSelect('domiciles', 'Id', 'Name', 'All Domiciles', 0);
            },
            Currencies() {
                return this.MapDataToSelect('currencies', 'Id', 'Description', 'All Currencies', 0);
            }
        },
        methods: {
            MapDataToSelect: function (property, valueProp, textProp, addDefault, defaultValue) {
                if (this[property] && this[property].map) {
                    var items = this[property].map(function (item) {
                        return {
                            value: item[valueProp],
                            text: item[textProp]
                        };
                    });
                    if (addDefault) {
                        items.unshift({
                            value: defaultValue,
                            text: addDefault
                        });
                    }
                    return items;
                } else {
                    return [];
                }
            },
            LoadFilterData: function (url, property) {
                fetch(url).then((response) => {
                    response.json().then((data) => {
                        this[property] = data;
                    });

                });
            },
            UpdateSearch(e) {
                if (e && e.preventDefault) e.preventDefault();

                this.$emit('filterUpdate', {
                    searchTerm: this.searchTermInput,
                    securityType: this.selectedSecurityType,
                    domicile: this.selectedDomicile,
                    currency: this.selectedCurrency
                });
            },
        },
        beforeMount() {
            this.searchTermInput = this.searchTerm
            this.LoadFilterData(this.domicilesUrl, 'domiciles');
            this.LoadFilterData(this.securityTypesUrl, 'securityTypes');
            this.LoadFilterData(this.currenciesUrl, 'currencies');
            //this.UpdateSearch();
        }
    };
</script>