var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company" },
    [
      _c("MarketNewsDataList", {
        attrs: {
          theme: _vm.theme,
          pageNo: _vm.pageNo,
          news: _vm.news,
          totalNews: _vm.totalNews,
          pages: _vm.pages,
        },
        on: { changePage: _vm.UpdatePage },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h3", [_vm._v("Company Announcements")]),
                _vm._v(" "),
                _c("Toggle", {
                  attrs: {
                    showLabels: "",
                    labelChecked: "NAVs are included",
                    labelUnchecked: "NAVs are excluded",
                  },
                  model: {
                    value: _vm.includeNavs,
                    callback: function ($$v) {
                      _vm.includeNavs = $$v
                    },
                    expression: "includeNavs",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }