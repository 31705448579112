<template>
    <div class="security-details-documents">

        <table v-if="documents && documents.length > 0" class="data small-table">
            <thead>
                <tr>
                    <th>Document</th>
                    <th>Updated</th>
                    <th>Created</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(document, index) in documents" :key="index">
                    <td><a :href="document.url"><strong>{{document.name}}</strong></a></td>
                    <td><a :href="document.url">{{ updatedAt(document) }}</a></td>
                    <td><a :href="document.url">{{ createdAt(document) }}</a></td>
                </tr>
            </tbody>
        </table>
        <p v-else>No documents were found.</p>

    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    export default {
        props: {
            theme: String,
            documents: {
                type: Array,
                required: true
            }
        },
        components: {

        },
        data() {
             return {
             };
        },
        computed: {
        },
        methods: {
            updatedAt(document) {
                return format(parseISO(document.updated), "dd-MM-yyyy HH:mm");
            },
            createdAt(document) {
                return format(parseISO(document.created), "dd-MM-yyyy HH:mm");
            }
        }
    };
</script>