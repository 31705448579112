var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "security-details-section security-details-section-news" },
    [
      _c("Toggle", {
        attrs: {
          showLabels: "",
          labelChecked: "NAVs are included",
          labelUnchecked: "NAVs are excluded",
        },
        model: {
          value: _vm.includeNavs,
          callback: function ($$v) {
            _vm.includeNavs = $$v
          },
          expression: "includeNavs",
        },
      }),
      _vm._v(" "),
      _vm.hasNews
        ? [
            _c("table", { staticClass: "data security-trades-table" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm.news && _vm.news.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.news, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.NewsItemUrl(item.Id) },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.OpenNews(item.Id)
                                },
                              },
                            },
                            [_c("strong", [_vm._v(_vm._s(item.Headline))])]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.Published))]),
                      ])
                    }),
                    0
                  )
                : _c("tbody", [_vm._m(1)]),
            ]),
            _vm._v(" "),
            _vm.countNews >= 10
              ? _c("div", { staticClass: "security-details-section" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      attrs: { href: `/market/companies/${this.companyId}` },
                    },
                    [_vm._v("More News")]
                  ),
                ])
              : _vm._e(),
          ]
        : _c("div", { staticClass: "loading-spinner" }),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: { open: _vm.modalOpen },
          on: {
            onClose: function ($event) {
              _vm.modalOpen = false
            },
          },
        },
        [
          _vm.modalContent
            ? _c("div", {
                staticClass: "modal__window__content",
                domProps: { innerHTML: _vm._s(_vm.modalContent) },
              })
            : _c("div", { staticClass: "modal__window__content loading" }, [
                _c("div", { staticClass: "loading-spinner" }),
              ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Headline")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date/Time")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "99" } }, [
        _vm._v(
          "\n                        No news items were found.\n                    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }