<template>
    <div v-if="enabled" v-show="isActive" class="tab">
        <slot></slot>
    </div>
</template>

<script>
    export default {
    props: {
      title: {
        type: String,
        default: 'Tab'
      },
      enabled: {
        type: Boolean,
        default: true
      },
      fullWidth: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isActive: false
      }
    }
  };
</script>