<template>
    <select :name="name" v-model="selectValue">
        <option :selected="selectValue == value.value" v-for="value in values" :value="value.value" :key="value.value">{{ value.text }}</option>
    </select>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true
            },
            values: {
                type: Array,
                required: true
            },
            value: {
                required: true
            }
        },
        data: function() {
            return {
                currentValue: this.value
            }
        },
         watch: {
        //     value(newVal, oldVal) {
        //         console.log(`value changed from ${oldVal} to ${newVal}`);
        //     }
            // $props: {
            //     handler() {
            //         console.log("handler");
            //         console.dir(arguments);
            //     },
            //     deep: false,
            //     immediate: true,
            // }
        },
        computed: {
            selectValue: {
                get() {
                    // console.log("selectValue:"+this.currentValue);
                    // console.dir(this);
                    return this.currentValue;
                },
                set (value) {
                    this.currentValue = value;
                    this.$emit('input', value);
                }
            }
        },
    };
</script>