<template>
    <div class="members-data-list">
        <div class="data-list">
            <div class="data-list-header">
                <slot name="header">
                    <output form="members-search-filter">{{ totalMembers }} results</output>
                </slot>
            </div>
            <div class="data-list-header">
                <div class="data-list-item">
                    <div class="data-list-item-field">Type &amp; Name</div>
                    <div class="data-list-item-field members-categories">Categories</div>
                    <div class="data-list-item-field">Jurisdiction</div>
                </div>
            </div>
            <div class="data-list-body">
                <div class="data-list-item" v-for="item in members" :key="item.Id">
                    <div class="data-list-item-field data-list-item-col members-name">
                        <a :href="MemberItemUrl(item.CompanyId)" class="members-company">{{ item.CompanyName }}</a>
                        <div class="members-type">{{ item.ListingDescription }}</div>
                    </div>
                    <div class="data-list-item-field members-categories">
                        <span class="members-categories-label">Category </span>{{ItemListingCategories(item)}}
                    </div>
                    <div class="data-list-item-field members-jurasdiction">{{ item.Country }}</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props: {
            theme: String
        },
        components: {

        },
        data() {
            return {
                memberItemBaseViewUrl: '/members/current-members/',
                membersUrl: '/umbraco/surface/ProxyApi/Sponsors/',
                members: []
            };
        },
        computed: {
            totalMembers() {
                if (this.members) return this.members.length;
                return 0;
            }
        },
        methods: {
            MemberItemUrl(id) {
                return this.memberItemBaseViewUrl + id;
            },
            LoadData() {

                fetch(this.membersUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                }).then((response) => {
                    response.json().then((data) => {
                        this.members = data;
                    });
                });
            },
            ItemListingCategories(item) {
                let categories = [];
                if (item.ListingCategory1) categories.push("1");
                if (item.ListingCategory2) categories.push("2");
                if (item.ListingCategory3) categories.push("3");
                return categories.join(", ");
            }
        },
        beforeMount() {
            this.LoadData();
        }
    };
</script>