var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.selectValue,
          expression: "selectValue",
        },
      ],
      attrs: { name: _vm.name },
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.selectValue = $event.target.multiple
            ? $$selectedVal
            : $$selectedVal[0]
        },
      },
    },
    _vm._l(_vm.values, function (value) {
      return _c(
        "option",
        {
          key: value.value,
          domProps: {
            selected: _vm.selectValue == value.value,
            value: value.value,
          },
        },
        [_vm._v(_vm._s(value.text))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }