<template>
    <div class="checkbox-toggle"
         role="checkbox"
         @keydown="toggle"
         @click.stop="toggle"
         tabindex="0"
         :aria-checked="toggled">

        <div class="checkbox-slide" :class="classes">
            <div class="checkbox-switch" :class="classes"></div>
        </div>
        <div v-show="showLabels" class="checkbox-label" v-html="label"></div>
   </div>
</template>

<script>
  export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Boolean,
            default: true
        },
        showLabels: {
            type: Boolean,
            default: false
        },
        labelChecked: {
            type: String,
            default: ""
        },
        labelUnchecked: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            toggled: this.value
        };
    },
    computed: {
        classes: function() {
            return {
                checked: this.toggled,
                unchecked: !this.toggled,
                disabled: this.disabled
            };
        },

        label: function() {
            return this.toggled && this.showLabels
                ? this.labelChecked
                : this.labelUnchecked;
        }
    },
    methods: {
        toggle: function(e) {
            if (this.disabled || e.keyCode === 9) { // not if disabled or tab is pressed
                e.stop();
            }

            this.toggled = ! this.toggled;
            this.$emit("input", this.toggled);
        }
    },


}
</script>