var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs_container" },
    [
      _c(
        "ul",
        { staticClass: "tabs" },
        _vm._l(_vm.enabledTabs, function (tab, index) {
          return _c(
            "li",
            {
              key: tab.title,
              class: { active: index == _vm.selectedIndex },
              on: {
                click: function ($event) {
                  return _vm.selectTab(index)
                },
              },
            },
            [_vm._v(_vm._s(tab.title))]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }