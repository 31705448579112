var waves = {
    options: {
        SEPARATION: 100,
        AMOUNTX: 50,
        AMOUNTY: 50
    },
    els: [],
    onWindowResize: function () {
        this.els.forEach(el => {
            el.mouseX = el.clientWidth / 2
            el.mouseY = -(el.clientHeight / 2)
            el.camera.aspect = window.innerWidth / window.innerHeight
            el.camera.updateProjectionMatrix()
            el.renderer.domElement.removeAttribute('style')
            el.renderer.domElement.removeAttribute('width')
            el.renderer.domElement.removeAttribute('height')
            el.renderer.setSize(el.clientWidth, el.clientHeight)
        })
    },
    render: function () {
        this.els.forEach(el => {
            el.camera.position.x += (el.mouseX - el.camera.position.x) * .05
            el.camera.position.y += (-el.mouseY - el.camera.position.y) * .05
            el.camera.lookAt(el.scene.position);
            var i = 0;
            for (var ix = 0; ix < this.options.AMOUNTX; ix++) {
                for (var iy = 0; iy < this.options.AMOUNTY; iy++) {
                    var particle = el.particles[i++];
                    particle.position.y = (Math.sin((ix + el.count) * 0.3) * 50) + (Math.sin((iy + el.count) * 0.5) * 50)
                    particle.scale.x = particle.scale.y = (Math.sin((ix + el.count) * 0.3) + 1) * 2 + (Math.sin((iy + el.count) * 0.5) + 1) * 2
                }
            }
            el.renderer.render(el.scene, el.camera)
            el.count += 0.1
        })
        requestAnimationFrame(this.render.bind(this))
    },
    init: function (selector) {
        this.els = Array.prototype.slice.call(document.querySelectorAll(selector), 0)
        window.addEventListener('resize', window.debounce(this.onWindowResize.bind(this), 400))
        this.els.forEach(el => {
            el.mouseX = el.clientWidth / 2
            el.mouseY = -(el.clientHeight / 2)
            el.camera = new THREE.PerspectiveCamera(110, window.innerWidth / window.innerHeight, 1, 10000)
            el.camera.position.z = 1000
            el.scene = new THREE.Scene()
            el.particles = new Array()
            el.count = 0

            var PI2 = Math.PI * 2;
            var material = new THREE.ParticleCanvasMaterial({
                color: parseFloat(el.dataset.color) || 0x54D5C4,
                opacity: 0.7,
                program: function (context) {
                    context.beginPath()
                    context.arc(0, 0, 1.5, 0, PI2, true)
                    context.fill()
                }
            })

            var i = 0
            for (var ix = 0; ix < this.options.AMOUNTX; ix++) {
                for (var iy = 0; iy < this.options.AMOUNTY; iy++) {
                    var particle = el.particles[i++] = new THREE.Particle(material)
                    particle.position.x = ix * this.options.SEPARATION - ((this.options.AMOUNTX * this.options.SEPARATION) / 2)
                    particle.position.z = iy * this.options.SEPARATION - ((this.options.AMOUNTY * this.options.SEPARATION) / 2)
                    el.scene.add(particle)
                }
            }

            el.renderer = new THREE.CanvasRenderer()
            el.renderer.setSize(el.clientWidth, el.clientHeight)
            el.appendChild(el.renderer.domElement)
        })
        this.render()
    }
}
export default waves
