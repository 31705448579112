<template>
    <div class="securities securities-embed nova-embed">
        <form>
            <div class="form-field">
                <input class="has-icon" placeholder="Start typing company name, mnemonic or ISIN" name="search-term" @keyup.enter="UpdateSearch" v-model="searchTerm" />
                <button @click="UpdateSearch"><i class="fas fa-search"></i></button>
            </div>
            <a class="btn" href="/market/securities" v-tracked><span>View all Market Data</span></a>
        </form>
        <SecuritiesDataList :emptyMessage="emptyMessage" :pageNo="1" :securities="securities" :totalSecurities="totalSecurities" :pages="1" :showExport="false" :exportFilter="{}" />
    </div>
</template>

<script>
    import SecuritiesDataList from './securities-datalist.vue';

    export default {
        props: {
            filter: {
                type: Object,
                required: false,
                default: {}
            },
            pageSize: {
                type: Number,
                required: false,
                default: 7
            },
            emptyMessage: {
                type: String,
                required: false,
                default: "No securities were found."
            }
        },
        components: {
            SecuritiesDataList
        },
        data() {
            return {
                baseUrl: '/market/securities',
                securitiesUrl: '/umbraco/surface/ProxyApi/AuctionSecurities',
                searchTerm: "",
                pageNo: 1,
                securities: [],
                totalSecurities: 0,
                pages: 0
            };
        },
        methods: {
            filterUpdate(filter) {
                this.searchTerm = filter.searchTerm;
                this.UpdateSearch();
            },
            UpdatePage(pageNo) {
                this.pageNo = pageNo;
                this.UpdateSearch();
            },
            UpdateSearch(e) {
                if (e && e.preventDefault) e.preventDefault();

                fetch(this.securitiesUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                    // body: JSON.stringify(this.SearchQuery)
                }).then((response) => {
                    response.json().then((data) => {
                        if (data && data.length > 0) {
                            this.securities = data.filter((s) => {
                                return (
                                    s.CompanyName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                                    s.Mnemonic.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                                    s.SecurityClassName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                                    s.SecurityTypeName.toLowerCase().includes(this.searchTerm.toLowerCase())
                                );
                            });
                            this.totalSecurities = data.length;
                        } else {
                            this.securities = [];
                            this.totalSecurities = 0;
                        }
                    });
                });

                this.securities = [];
                this.totalSecurities = 0;
            }
        },
        beforeMount() {
            this.UpdateSearch();
        }
    };
</script>