<template>
    <div class="tabs_container">
      <ul class="tabs">
        <li v-for="(tab, index) in enabledTabs" :key="tab.title" @click="selectTab(index)" :class='{"active": (index == selectedIndex)}'>{{ tab.title }}</li>
      </ul>
      <slot></slot>
    </div>
</template>

<script>
  export default {
    data () {
      return {
        selectedIndex: 0, // the index of the selected tab,
        tabs: [],        // all of the tabs
      }
    },
    computed: {
      enabledTabs() {
        return this.tabs.filter(tab => tab.enabled);
      }
    },
    methods: {
      selectTab (i) {
        this.selectedIndex = i
        this.enabledTabs.forEach((tab, index) => {
          tab.isActive = (index === i)
        });
      }
    },
    created () {
      this.tabs = this.$children
    },
    mounted () {
      this.selectTab(0)
    }
  };
</script>