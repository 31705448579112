<template>
    <div class="security-details-section security-details-section-news">

        <Toggle v-model="includeNavs" showLabels labelChecked="NAVs are included" labelUnchecked="NAVs are excluded" />

        <template v-if="hasNews">

            <table class="data security-trades-table">
                <thead>
                    <tr>
                        <th>Headline</th>
                        <th>Date/Time</th>
                    </tr>
                </thead>
                <tbody v-if="news && news.length > 0">
                    <tr v-for="(item, index) in news" :key="index">
                        <td>
                            <a :href="NewsItemUrl(item.Id)" @click.prevent="OpenNews(item.Id)">
                                <strong>{{item.Headline}}</strong>
                            </a>
                        </td>
                        <td>{{item.Published}}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="99">
                            No news items were found.
                        </td>
                    </tr>
                </tbody>
            </table>

            <div v-if="countNews >= 10" class="security-details-section">
                <a :href="`/market/companies/${this.companyId}`" class="btn">More News</a>
            </div>

        </template>

        <div v-else class="loading-spinner"></div>

        <Modal :open="modalOpen" @onClose="modalOpen = false">
            <div v-if="modalContent" class="modal__window__content" v-html="modalContent" />
            <div v-else class="modal__window__content loading">
                <div class="loading-spinner"></div>
            </div>
        </Modal>
    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Modal from '../utils/modal.vue';
    import Toggle from '../utils/toggle.vue';

    export default {
        props: {
            theme: String,
            companyId: {
                type: Number,
                required: true
            }
        },
        components: {
            Modal,
            Toggle
        },
        data() {
            return {
                newsItemBaseUrl: '/umbraco/surface/proxyapi/NewsItem?id=',
                newsItemBaseViewUrl: '/market/news/',
                news: null,
                showNews: 10,
                modalOpen: false,
                modalContent: null,
                includeNavs: true,
            };
        },
        computed: {
            newsUrl() {
                //return `/umbraco/surface/ProxyApi/CompanyNews/${this.companyId}?PageNo=1&PageSize=${this.showNews}`;
                // return `/umbraco/surface/ProxyApi/CompanyNews/${this.companyId}?PageNo=1&PageSize=${this.showNews}`;
                return '/api/companynews'
            },
            hasNews() {
                return !!this.news;
            },
            countNews() {
                if (this.news) {
                    return this.news.length;
                }
                return 0;
            },
            SearchQuery() {
                return {
                    Page: 1,
                    ItemsPerPage: this.showNews,
                    CompanyId: this.companyId,
                    IncludeNavs: this.includeNavs
                }
            }
        },
        watch: {
            includeNavs: function(oldValue, newValue) {
                this.LoadData();
            }
        },
        methods: {
            NewsItemUrl(id) {
                return this.newsItemBaseViewUrl + id;
            },
            OpenNews(id) {
                this.OpenModal(this.newsItemBaseViewUrl + id);
            },
            OpenModal(contentUrl) {
                this.modalContent = null;
                this.modalOpen = true;

                // load the content
                fetch(contentUrl).then((response) => {
                    response.text().then((data) => {
                        this.modalContent = data;
                    });
                });
            },
            LoadData() {
                let vm = this
                const newsPromise = fetch(this.newsUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.SearchQuery)
                });

                newsPromise.
                    then(response => response.json()).
                    then((data) => {
                        if (data) {
                            const news = data.News || data.CompanyNews;
                            vm.news = news.map((t) => {
                                return Object.assign(t, {
                                    Published: t.Published ? format(parseISO(t.Published), "dd-MM-yyyy HH:mm") : null
                                });
                            });
                        } else {
                            vm.news = [];
                        }

                    });

            },
        },
        beforeMount() {
            this.LoadData();
        }
    };
</script>