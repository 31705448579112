var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "security-details-auction" }, [
    _c("div", { staticClass: "field-list" }, [
      _c("div", { staticClass: "field-list-field" }, [
        _c("div", { staticClass: "field-list-field col" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm.auction.indicative &&
          Number.parseFloat(_vm.auction.indicative) > 0
            ? _c("output", [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.currency) +
                      " " +
                      _vm._s(
                        Number.parseFloat(_vm.auction.indicative).toFixed(2)
                      )
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field-list-field col" }, [
          _c("label", [_vm._v("Next auction date:")]),
          _vm._v(" "),
          _c("output", [_vm._v(_vm._s(_vm.DisplayNextAuction))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field-list-field col" }, [
          _c("label", [_vm._v("Previous auction date:")]),
          _vm._v(" "),
          _c("output", [_vm._v(_vm._s(_vm.DisplayPrevAuction))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field-list-field col" }, [
          _c("label", [_vm._v("Updated:")]),
          _vm._v(" "),
          _c("output", [_vm._v(_vm._s(_vm.updateAt))]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "security-details-auction-orders" }, [
      _vm.hasBuyOrders
        ? _c("div", { staticClass: "security-details-auction-orders-col" }, [
            _c("h4", [_vm._v("Buy orders")]),
            _vm._v(" "),
            _c("table", { staticClass: "data security-navs-table" }, [
              _c("thead", [
                _c("tr", { staticClass: "alternative-color" }, [
                  _c("th", [_vm._v("Volume")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Price (" + _vm._s(_vm.currency) + ")")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.auction.buy.orders, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(item.quantity))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(Number.parseFloat(item.price).toFixed(2))),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasSellOrders
        ? _c("div", { staticClass: "security-details-auction-orders-col" }, [
            _c("h4", [_vm._v("Sell orders")]),
            _vm._v(" "),
            _c("table", { staticClass: "data security-navs-table" }, [
              _c("thead", [
                _c("tr", { staticClass: "alternative-color" }, [
                  _c("th", [_vm._v("Price (" + _vm._s(_vm.currency) + ")")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Volume")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.auction.sell.orders, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [
                      _vm._v(_vm._s(Number.parseFloat(item.price).toFixed(2))),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.quantity))]),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [_c("h4", [_vm._v("Indicative price:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }