import Vue from '../vue-config'

if (document.getElementById('articles-vm')) {
    var vm = new Vue({
        el: '#articles-vm',
        data: {
            categories: categories,
            query: '',
            category: -1,
            pages: 0,
            page: 0,
            total: 0,
            results: []
        },
        computed: {
        },
        watch: {
        },
        methods: {
            doSearch() {
                var vm = this
                fetch('/umbraco/api/articles/search', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        query: vm.query,
                        category: vm.category,
                        page: vm.page,
                        perPage: 10
                    })
                }).then((response) => {
                    response.json().then((data) => {
                        vm.results = data.Results
                        vm.total = data.Total
                        vm.pages = data.Pages
                    })
                })
            },
            updatePage(p) {
                this.page = p
                this.doSearch()
            }
        },
        mounted() {
            var vm = this
            vm.doSearch()
        }
    })
}