<template>
    <div class="article market-news">
        <div class="article-meta">
            <div class="article-meta-container">
                <h1>{{ title }}</h1>
                <p v-if="description" class="">{{ description }}</p>
                <form :key="renderFilterKey" v-if="renderFilter" >
                    <div class="form-field">
                        <input class="has-icon" placeholder="Company name" name="search-term" v-model="searchTerm" />
                        <button @click="Search"><i class="fas fa-search"></i></button>
                    </div>
                    <Toggle v-model="includeNavs" showLabels labelChecked="NAVs are included" labelUnchecked="NAVs are excluded" />
                </form>
            </div>
        </div>
        <div class="article-content">
            <MarketNewsDataList @changePage="UpdatePage" :theme="theme" :pageNo="pageNo" :news="news" :totalNews="totalNews" :pages="pages" />
        </div>
    </div>
</template>

<script>
    import MarketNewsDataList from './market-news-datalist.vue';
    import Toggle from './utils/toggle.vue';

    export default {
        props: {
            theme: String,
            title: {
                type: String,
                required: true
            },
            description: {
                type: String
            },
        },
        components: {
            MarketNewsDataList,
            Toggle
        },
        data() {
             return {
                 baseUrl: '/market/news',
                 newsUrl: '/api/news',
                 searchTerm: "",
                 includeNavs: true,
                 pageNo: 1,
                 pageSize: 16,
                 news: [],
                 totalNews: 0,
                 pages: 0,
                 renderFilter: true,
                 renderFilterKey: "renderFilterKey"
             };
        },
        watch: {
            includeNavs: function(oldValue, newValue) {
                this.UpdateSearch();
            }
        },
        computed: {
            SearchQuery() {
                return {
                    Page: this.pageNo,
                    ItemsPerPage: this.pageSize,
                    Terms: this.searchTerm,
                    IncludeNavs: this.includeNavs
                }
            },
            pushstateData: {
                get() {
                    return {
                        Page: this.pageNo,
                        ItemsPerPage: this.pageSize,
                        Terms: this.searchTerm,
                        IncludeNavs: this.includeNavs
                    };
                },
                set(value) {
                    try {
                        if (value && value != "") {
                            let stateObj = JSON.parse(value);
                            if (stateObj) {

                                this.searchTerm = stateObj.Terms;
                                this.pageSize = stateObj.ItemsPerPage;
                                this.pageNo = stateObj.Page;
                                this.includeNavs = stateObj.IncludeNavs;
                                this.UpdateSearch(false);

                                // force recreation of filter, this is awful but need to find
                                // a way to set props on v-model components (selects lower down the tree)
                                // after they have been created.
                                this.renderFilter = false;
                                this.$nextTick(() => {
                                    this.renderFilter = value;
                                    this.renderFilterKey = true;
                                });
                            }
                        }
                    } catch (e) {
                        console.warn("Pushstate data could not be parsed.");
                    }
                }
            }, // pushstateData
        },
        methods: {
            UpdatePage(pageNo) {
                this.pageNo = pageNo;
                this.UpdateSearch();
            },
            popstate() {
                // back/forward handler
                this.pushstateData = this.getHash();
            },
            Search(e) {
                if (e && e.preventDefault) e.preventDefault();

                this.UpdateSearch();
            },
            UpdateSearch(updateState) {

                // ensure we don't update pushstate FROM pushstate
                if (updateState === undefined) updateState = true;
                if (updateState) {
                    this.setHash(JSON.stringify(this.SearchQuery), false); // remember current state
                }

                fetch(this.newsUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.SearchQuery)
                }).then((response) => {
                    response.json().then((data) => {
                        this.news = data.News;
                        this.totalNews = data.Total;
                        this.pages = data.TotalPages;
                    });
                });
            },
        },
        created() {
            // get hash and update state to reflect data
            let hash = this.getHash();
            this.pushstateData = hash;

            // also listen for popstate and do likewise
            window.addEventListener('popstate', this.popstate);
        },
        beforeUnmount() {
            // unregister our popstate listener
            window.removeEventListener('popstate', this.popstate);
        },
        beforeMount() {
            this.UpdateSearch();
        }
    };
</script>