<template>
    <div class="market-update market-update-recent-trades">

        <table v-if="hasRecentTrades" class="data security-trades-table">
            <thead>
                <tr>
                    <th>Mnemonic</th>
                    <th>Price</th>
                    <th>Size</th>
                    <th>Currency</th>
                    <th>Time/Date</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in recentTrades" :key="index">
                    <td><a :href="SecurityUrl(item)">{{item.Mnemonic}}</a></td>
                    <td>{{item.Price}}</td>
                    <td>{{item.Size}}</td>
                    <td>{{item.CurrencyCode}}</td>
                    <td>{{item.TradeDate}}</td>
                </tr>
            </tbody>
        </table>

        <div v-else class="loading-spinner"></div>
    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    export default {
        props: {
            theme: String
        },
        components: {
        },
        data() {
             return {
                 recentTrades: null,
                 showTrades: 25
             };
        },
        computed: {
            recentTradesUrl() {
                return `/umbraco/surface/ProxyApi/RecentTrades?PageNo=1&PageSize=${this.showTrades}`;
            },
            hasRecentTrades() {
                return !!this.recentTrades;
            }
        },
        methods: {
            SecurityUrl(item) {
                return `/market/securities/${item.SecurityClassId}`;
            },
            LoadData() {

                const recentTradesPromise = fetch(this.recentTradesUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                recentTradesPromise.
                    then(response => response.json()).
                    then((data) => {
                        this.recentTrades = data.map((t) => {
                            return Object.assign(t, {
                                Published: t.Published ? format(parseISO(t.Published), "dd-MM-yyyy HH:mm") : null,
                                TradeDate: t.TradeDate ? format(parseISO(t.TradeDate), "dd-MM-yyyy HH:mm") : null,
                            });
                        });

                });

            },
        },
        beforeMount() {
            this.LoadData();
        }
    };
</script>