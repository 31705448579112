<template>
    <div class="securities-data-list">
        <div class="data-list">
            <div class="data-list-header">
                <slot name="header">
                    <output form="securities-search-filter">{{ totalSecurities }} results</output>
                </slot>
            </div>
            <div class="data-list-header">
                <div class="data-list-item">
                    <div class="data-list-item-field">Name & Mnemonic</div>
                    <div class="data-list-item-field">Currency</div>
                    <div class="data-list-item-field">Type</div>
                </div>
            </div>
            <div v-if="securities && securities.length > 0" class="data-list-body">
                <div class="data-list-item" v-for="security in securities" :key="security.SecurityClassId">
                    <div class="data-list-item-field data-list-item-col">
                        <a :href="SecurityUrl(security)" class="security-company"><strong>{{ security.CompanyName }}</strong></a>
                        <div class="security-mnemonic">{{ security.Mnemonic }}</div>
                    </div>
                    <div class="data-list-item-field security-currency">{{ security.CurrencyCode }}</div>
                    <div class="data-list-item-field data-list-item-col">
                        <div data-label="type" class="data-list-field security-type">{{ security.SecurityTypeName }}</div>
                        <div class="data-list-field security-class"><em>{{ security.SecurityClassName }}</em></div>
                    </div>
                </div>
            </div>
            <div v-else-if="securities && securities.length == 0" class="data-list-body">
                <div class="data-list-item">{{ emptyMessage }}</div>
            </div>
        </div>
        <div class="data-list-postscript">
            <Pagination v-if="showPagination" @updatePage="UpdatePage" :pages="pages" :current="pageNo" :showMaximum="6" :showPrevious="false" :showNext="false" />
            <!-- <a v-if="showExport" class="btn" :href="ExportUrl"><span>Export CSV File</span></a> -->
        </div>
    </div>
</template>

<script>
    import Pagination from './utils/pagination.vue';

    export default {
        props: {
            theme: String,
            securities: {
                type: Array,
                required: true
            },
            totalSecurities: {
                type: Number,
                required: true
            },
            pages: {
                type: Number,
                required: true
            },
            pageNo: {
                type: Number,
                required: true
            },
            showPagination: {
                type: Boolean,
                default: true
            },
            showExport: {
                type: Boolean,
                default: true
            },
            exportFilter: {
                type: Object,
                default: {}
            },
            emptyMessage: {
                type: String,
                default: "No items were found."
            }
        },
        components: {
            Pagination
        },
        data() {
            return {
                baseUrl: '/market/securities'
            };
        },
        computed: {
            ExportUrl() {
                const qs = Object.keys(this.exportFilter).map(key => `${key}=${this.exportFilter[key]}`).join('&');
                return `/market/securities/download?${qs}`;
            }
        },
        methods: {
            SecurityUrl(security) {
                return `${this.baseUrl}/${security.SecurityClassId}`;
            },
            UpdatePage(pageNo) {
                this.$emit('changePage', pageNo);
                this.$el.scrollIntoView(true);
            }
        }
    };
</script>