var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "securities securities-embed" },
    [
      _c("form", [
        _c("div", { staticClass: "form-field" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchTerm,
                expression: "searchTerm",
              },
            ],
            staticClass: "has-icon",
            attrs: {
              placeholder: "Start typing company name, mnemonic or ISIN",
              name: "search-term",
            },
            domProps: { value: _vm.searchTerm },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.UpdateSearch.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchTerm = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("button", { on: { click: _vm.UpdateSearch } }, [
            _c("i", { staticClass: "fas fa-search" }),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [{ name: "tracked", rawName: "v-tracked" }],
            staticClass: "btn",
            attrs: { href: "/market/securities" },
          },
          [_c("span", [_vm._v("View all Market Data")])]
        ),
      ]),
      _vm._v(" "),
      _c("SecuritiesDataList", {
        attrs: {
          emptyMessage: _vm.emptyMessage,
          pageNo: _vm.pageNo,
          securities: _vm.securities,
          totalSecurities: _vm.totalSecurities,
          pages: _vm.pages,
          exportFilter: _vm.filter,
        },
        on: { changePage: _vm.UpdatePage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }