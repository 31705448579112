<template>
    <div class="data-cell" v-if="value">
        <span class="key">{{ title }}</span>
        <span class="val">{{ value }}</span>
    </div>
</template>

<script>
    export default {
    props: {
      title: {
        type: String        
      },
      value: {
        type: String
      }
    }    
  };
</script>