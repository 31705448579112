var Flickity = require('flickity')
import scroll from './scroll'
//import waves from './waves'
import LazyLoad from 'vanilla-lazyload'
import 'vanilla-cookieconsent'
var cookieconsent = initCookieConsent()


window.debounce = function (func, wait, immediate) {
    var timeout
    return function () {
        var context = this, args = arguments
        var later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

window.addEventListener('resize', window.debounce(function () {
    debouncedResize()
}, 400))
debouncedResize()


function addEventListeners(el, events, fn) {
    events.forEach(e => el.addEventListener(e, fn, false))
}

function each(selector, fn) {
    document.querySelectorAll(selector).forEach(el => fn(el))
}


//Tracked Elements
each('.tracked', function (el) {
    el.addEventListener('touchstart', function (e) { el.classList.add('touched') })
    el.addEventListener('touchend', function (e) { el.classList.remove('touched') })
    addEventListeners(el, ['mousemove', 'touchmove'], function (e) {
        var r = el.getBoundingClientRect()
        var x = e.touches ? e.touches[0].clientX - r.left : e.layerX
        var y = e.touches ? e.touches[0].clientY - r.top : e.layerY
        el.style.setProperty('--x', `${x}px`)
        el.style.setProperty('--y', `${y}px`)
    })
})


//Info Panel Popouts
each('.infopanels-items-item.popup', function (el) {
    el.addEventListener('click', function (e) {
        var key = el.parentElement.dataset.key
        var popout = document.querySelector(`.infopanels-popout[data-key=${key}]`)
        popout.classList.add('on')
        document.documentElement.classList.add('noscroll')

        var slider = popout.querySelector('.infopanels-popout-slider')
        var flkty = Flickity.data(slider)
        flkty.resize()
        var itemIndex = parseInt(el.dataset.index)
        window.setTimeout(function () {
            flkty.selectCell(itemIndex)
        }, 500)
    })
})
//Info Panel Popout Sliders
each('.infopanels-popout', function (el) {
    var slider = el.querySelector('.infopanels-popout-slider')
    new Flickity(slider, {
        wrapAround: true
    })
    el.querySelector('i.fa-times').addEventListener('click', function (e) {
        el.classList.remove('on')
        document.documentElement.classList.remove('noscroll')
    })
})


//Product Unit Popovers
each('.products-list-item.popup', function (el) {
    var id = el.dataset.id
    var popup = document.querySelector(`.products-popup[data-id='${id}']`)
    if (popup != null) {
        el.addEventListener('click', function (e) {
            e.preventDefault()
            popup.classList.add('on')
        })
        popup.addEventListener('click', function (e) {
            e.preventDefault()
            e.stopPropagation()
            popup.classList.remove('on')
        })
    }
})

//Accordions
each('.accordion-item a.opener', function (el) {
    el.onclick = function () {
        el.parentElement.classList.toggle('on')
    }
})



//Page nav
each('.pagenav-inner-items a', function (el) {
    el.onclick = function (e) {
        e.preventDefault()
        var target = el.href.split('#')[1]
        var targetEl = document.querySelector('#' + target)
        window.scrollTo({
            top: targetEl.offsetTop - 43,
            behavior: 'smooth'
        })
    }
})
each('.pagenav-inner a.title', function (el) {
    el.onclick = function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
})


//Text Sliders
each('.textslider-slides', function (el) {
    new Flickity(el, {
        wrapAround: true
    })
})


function debouncedResize() {
    each('.products-list', function (el) {
        var flk = Flickity.data(el)
        var bp = parseInt(el.dataset.break) || 1080

        if (window.innerWidth >= bp) {
            if (flk != undefined) flk.destroy()
        } else {
            new Flickity(el, {
                prevNextButtons: true,
                cellAlign: 'center',
                resize: true,
                wrapAround: true,
                pageDots: false
            })
        }
    })
}


scroll.init()

window.lazy = new LazyLoad({
})



cookieconsent.run({
    page_scripts: true,
    gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    },
    languages: {
        'en': {
            consent_modal: {
                title: 'This website uses cookies.',
                description: 'Please refer to our <a href="https://tisegroup.com/privacy-statement/">Privacy Policy</a> for further information. By continuing to use this website, you agree to our use of cookies.',
                primary_btn: {
                    text: 'Accept',
                    role: 'accept_all'      // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Settings',
                    role: 'settings'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                blocks: [
                    {
                        title: 'Cookie Categories',
                        description: 'You can review each type of cookie below and decide whether to accept each.'
                    },
                    {
                        title: 'Strictly Necessary',
                        description: 'These cookies are required for the site to function and cannot be disabled.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        }
                    },
                    {
                        title: 'Analytics cookies',
                        description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymised and cannot be used to identify you.',
                        toggle: {
                            value: 'analytics',
                            enabled: true,
                            readonly: false
                        },
                    }
                ]
            }
        }
    }
});
