import Vue from '../vue-config'
import Members from '../components/members.vue';
import Tab from '../components/utils/tab.vue';
import Tabs from '../components/utils/tabs.vue';

if (document.getElementById('members-vm')) {

    var vm = new Vue({
        el: '#members-vm',
        components: {
            Members,
            Tabs,
            Tab
        },
        // data: {
        // },
        // computed: {
        // },
        // watch: {
        // },
        // methods: {
        // },
        // mounted() {
        // }
    })
}