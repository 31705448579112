<template>
    <div class="company">
        <MarketNewsDataList @changePage="UpdatePage" :theme="theme" :pageNo="pageNo" :news="news" :totalNews="totalNews" :pages="pages">
            <template v-slot:header>
            <h3>Company Announcements</h3>
            <Toggle v-model="includeNavs" showLabels labelChecked="NAVs are included" labelUnchecked="NAVs are excluded" />
            </template>
        </MarketNewsDataList>
    </div>
</template>

<script>
    import MarketNewsDataList from './market-news-datalist.vue';
    import Toggle from './utils/toggle.vue';

    export default {
        props: {
            theme: String,
            id: {
                type: Number,
                required: true
            }
        },
        components: {
            MarketNewsDataList,
            Toggle
        },
        data() {
             return {
                 newsUrl: '/api/companynews',
                 includeNavs: true,
                 pageNo: 1,
                 pageSize: 20,
                 news: [],
                 totalNews: 0,
                 pages: 0
             };
        },
        watch: {
            includeNavs: function(oldValue, newValue) {
                this.UpdateSearch();
            }
        },
        computed: {
            SearchQuery() {
                return {
                    Page: this.pageNo,
                    ItemsPerPage: this.pageSize,
                    CompanyId: this.id,
                    IncludeNavs: this.includeNavs
                }
            }
        },
        methods: {
            UpdatePage(pageNo) {
                this.pageNo = pageNo;
                this.UpdateSearch();
            },
            UpdateSearch(e) {
                if (e && e.preventDefault) e.preventDefault();

                fetch(this.newsUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.SearchQuery)
                }).then((response) => {
                    response.json().then((data) => {
                        this.news = data.News;
                        this.totalNews = data.Total;
                        this.pages = data.TotalPages;
                    });
                });
            },
        },
        beforeMount() {
            this.UpdateSearch();
        }
    };
</script>