<template>
    <div class="market-news-data-list">
        <div class="data-list">
            <div class="data-list-header">
                <slot name="header">
                    <output form="market-news-search-filter">{{ totalNews }} results</output>
                </slot>
            </div>
            <div class="data-list-header">
                <div class="data-list-item">
                    <div class="data-list-item-field">Company</div>
                    <div class="data-list-item-field market-news-headline">Headline</div>
                    <div class="data-list-item-field">Date/Time</div>
                </div>
            </div>
            <div class="data-list-body">
                <div class="data-list-item" v-for="item in news" :key="item.Id">
                    <div class="data-list-item-field data-list-item-col">
                        <a :href="CompanyUrl(item)" class="market-news--company"><strong>{{ item.CompanyName }}</strong></a>
                        <div class="market-news-mnemonic">{{ item.Mnemonic }}</div>
                    </div>

                    <div class="data-list-item-field market-news-headline">
                        <a :href="NewsItemUrl(item.Id)" @click.prevent="OpenNews(item.Id)">{{item.Headline}}</a>
                    </div>
                    <div class="data-list-item-field market-news-datetime">{{ FormatDateTime(item.Published) }}</div>
                </div>
            </div>
        </div>
        <div class="data-list-postscript">
            <Pagination @updatePage="UpdatePage" :pages="pages" :current="pageNo" :showMaximum="6" :showPrevious="false" :showNext="false" />
        </div>
        <Modal :open="modalOpen" @onClose="modalOpen = false">
            <div v-if="modalContent" class="modal__window__content" v-html="modalContent" />
            <div v-else class="modal__window__content loading">
                <div class="loading-spinner"></div>
            </div>
        </Modal>
    </div>
</template>


<script>
    import { format, parseISO } from 'date-fns';
    import Modal from './utils/modal.vue';
    import Pagination from './utils/pagination.vue';

    export default {
        props: {
            theme: String,
            news: {
                type: Array,
                required: true
            },
            totalNews: {
                type: Number,
                required: true
            },
            pages: {
                type: Number,
                required: true
            },
            pageNo: {
                type: Number,
                required: true
            }
        },
        components: {
            Modal,
            Pagination
        },
        data() {
            return {
                //newsItemBaseUrl: '/umbraco/surface/proxyapi/NewsItem?id=',
                newsItemBaseViewUrl: '/market/news/',
                baseCompanyUrl: '/market/companies',
                 modalOpen: false,
                 modalContent: null
            };
        },
        computed: {
            newsUrl() {
                return `/umbraco/surface/ProxyApi/CompanyNews/${this.companyId}?PageNo=1&PageSize=${this.showNews}`;
            }
        },
        methods: {
            CompanyUrl(news) {
                return `${this.baseCompanyUrl}/${news.CompanyId}`;
            },
            NewsItemUrl(id) {
                return this.newsItemBaseViewUrl + id;
            },
            OpenNews(id) {
                this.OpenModal(this.newsItemBaseViewUrl + id);
            },
            OpenModal(contentUrl) {
                this.modalContent = null;
                this.modalOpen = true;

                // load the content
                fetch(contentUrl).then((response) => {
                    response.text().then((data) => {
                        this.modalContent = data;
                    });
                });
            },FormatDateTime(value) {
                if (value) return format(parseISO(value), "dd-MM-yyyy HH:mm");
                return '';
            },
            UpdatePage(pageNo) {
                this.$emit('changePage', pageNo);
                this.$el.scrollIntoView(true);
            }
        }
    };
</script>