var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pages > 1
    ? _c(
        "ul",
        { staticClass: "pagination" },
        _vm._l(_vm.buttons, function (button) {
          return _c("li", { key: button.key }, [
            _c(
              "button",
              {
                class: {
                  active: button.key == _vm.current,
                  link: button.link,
                },
                on: {
                  click: function ($event) {
                    return _vm.UpdatePage(button)
                  },
                },
              },
              [_vm._v(_vm._s(button.text))]
            ),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }