import Vue from '../vue-config'

if (document.getElementById('navigation')) {
    var vm = new Vue({
        el: '#navigation',
        data: {
            open: false,
            searchOpen: false,
            curPage: null,
            structure: navData,
            openNavs: [],
        },
        computed: {
        },
        watch: {
        },
        methods: {
            isMobile() {
                return window.innerWidth < 1080
            },
            toggleNav(p) {
                var vm = this
                if (vm.isMobile()) {
                    if (p == null) vm.open = !vm.open //clicked burger
                    if (p != null) {
                        if (this.openNavs.includes(p.Id)) {
                            this.openNavs.splice(this.openNavs.indexOf(p.Id), 1)
                        } else {
                            this.openNavs = []
                            this.openNavs.push(p.Id)
                        }
                    }
                } else {
                    if (vm.open && (p == null || p.Id == vm.curPage.Id)) {
                        vm.open = false
                    } else {
                        if (p != null) vm.curPage = p
                        vm.open = true
                    }
                }
                vm.toggleNoScroll()
            },
            toggleSearch() {
                this.searchOpen = !this.searchOpen
                if (this.searchOpen && this.open) this.open = false
                vm.toggleNoScroll()
            },
            toggleNoScroll() {
                var vm = this
                if (vm.open) {
                    document.documentElement.classList.add('noscroll')
                } else {
                    document.documentElement.classList.remove('noscroll')
                }
            },
            addEventListeners(el, events, fn) {
                events.forEach(e => el.addEventListener(e, fn, false))
            },
            closeSearch() {
                if (this.searchOpen) this.searchOpen = false
            }
        },
        mounted() {
            var vm = this
            vm.curPage = this.structure[0]
            var index = 0;
            for (var i = 0; i < this.structure.length; i++) {
                if (this.structure[i].Title == 'Membership') {
                    index = i;
               }
            }
            if (index > 0) {
                this.structure.splice(index + 1, 0, {
                    "Id": 3755, "Name": "Private Markets", "Title": "Private Markets", "Copy": "Private Markets.", "Url": "https://tiseprivatemarkets.com/", "HasGrandchildren": false, "Children": []
                });
            }
        }
    })
}