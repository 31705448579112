var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "checkbox-toggle",
      attrs: { role: "checkbox", tabindex: "0", "aria-checked": _vm.toggled },
      on: {
        keydown: _vm.toggle,
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggle.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "checkbox-slide", class: _vm.classes }, [
        _c("div", { staticClass: "checkbox-switch", class: _vm.classes }),
      ]),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLabels,
            expression: "showLabels",
          },
        ],
        staticClass: "checkbox-label",
        domProps: { innerHTML: _vm._s(_vm.label) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }