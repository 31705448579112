var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "securities-data-list" }, [
    _c("div", { staticClass: "data-list" }, [
      _c(
        "div",
        { staticClass: "data-list-header" },
        [
          _vm._t("header", function () {
            return [
              _c("output", { attrs: { form: "securities-search-filter" } }, [
                _vm._v(_vm._s(_vm.totalSecurities) + " results"),
              ]),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.securities && _vm.securities.length > 0
        ? _c(
            "div",
            { staticClass: "data-list-body" },
            _vm._l(_vm.securities, function (security) {
              return _c(
                "div",
                {
                  key: security.SecurityClassId,
                  staticClass: "data-list-item",
                },
                [
                  _c(
                    "div",
                    { staticClass: "data-list-item-field data-list-item-col" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "security-company",
                          attrs: { href: _vm.SecurityUrl(security) },
                        },
                        [_c("strong", [_vm._v(_vm._s(security.CompanyName))])]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "security-mnemonic" }, [
                        _vm._v(_vm._s(security.Mnemonic)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "data-list-item-field security-currency" },
                    [_vm._v(_vm._s(security.CurrencyCode))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "data-list-item-field data-list-item-col" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "data-list-field security-type",
                          attrs: { "data-label": "type" },
                        },
                        [_vm._v(_vm._s(security.SecurityTypeName))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "data-list-field security-class" },
                        [_c("em", [_vm._v(_vm._s(security.SecurityClassName))])]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _vm.securities && _vm.securities.length == 0
        ? _c("div", { staticClass: "data-list-body" }, [
            _c("div", { staticClass: "data-list-item" }, [
              _vm._v(_vm._s(_vm.emptyMessage)),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "data-list-postscript" },
      [
        _vm.showPagination
          ? _c("Pagination", {
              attrs: {
                pages: _vm.pages,
                current: _vm.pageNo,
                showMaximum: 6,
                showPrevious: false,
                showNext: false,
              },
              on: { updatePage: _vm.UpdatePage },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-list-header" }, [
      _c("div", { staticClass: "data-list-item" }, [
        _c("div", { staticClass: "data-list-item-field" }, [
          _vm._v("Name & Mnemonic"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "data-list-item-field" }, [
          _vm._v("Currency"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "data-list-item-field" }, [_vm._v("Type")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }