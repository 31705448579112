<template>
    <div class="sustainable-issuers table-scrollable-container">

        <table v-if="issuers" class="data sustainable-issuers-table">
            <thead>
                <tr>
                    <th>Issuer</th>
                    <th>Segment Admission Date</th>
                    <th>Credentials</th>
                    <th>Information</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in issuers" :key="index">
                    <td class="nowrap-xl"><a :href="CompanyUrl(item)">{{item.CompanyName}}</a></td>
                    <td class="nowrap">{{item.AdmissionDate}}</td>
                    <td><div v-for="(cred, credIndex) in item.Credentials" :key="credIndex">{{ cred }}</div></td>
                    <td><a :href="item.Url">Link</a></td>
                </tr>
            </tbody>
        </table>

        <div v-else class="loading-spinner"></div>
    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    export default {
        props: {
            theme: String
        },
        components: {
        },
        data() {
             return {
                 issuers: null,
                 securitiesUrl: '/umbraco/surface/ProxyApi/SustainableIssuers'
             };
        },
        computed: {
        },
        methods: {
            CompanyUrl(item) {
                return `/market/companies/${item.Id}`;
            },
            LoadData() {

                const securitiesPromise = fetch(this.securitiesUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                securitiesPromise.
                    then(response => response.json()).
                    then((data) => {
                        this.issuers = data.map((x) => {
                            return Object.assign(x, {
                                AdmissionDate: x.AdmissionDate ? format(parseISO(x.AdmissionDate), "dd-MM-yyyy") : null,
                            });
                        });

                });

            },
        },
        beforeMount() {
            this.LoadData();
        }
    };
</script>