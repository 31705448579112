var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "security-details-navs" },
    [
      _vm.chartOptions
        ? _c("chart", {
            ref: "highChart",
            attrs: {
              "constructor-type": "stockChart",
              options: _vm.chartOptions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.navsLoaded
        ? _c("div", { staticClass: "data security-details-section" }, [
            _c("table", { staticClass: "data security-navs-table" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.navs, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(item.CurrencyCode))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.NAV))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          item.PercentageChange || item.PercentageChange === 0
                            ? Number.parseFloat(item.PercentageChange).toFixed(
                                2
                              )
                            : "-"
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.NAVDate))]),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _c("div", { staticClass: "loading-spinner" }),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: { open: _vm.modalOpen },
          on: {
            onClose: function ($event) {
              _vm.modalOpen = false
            },
          },
        },
        [
          _vm.modalContent
            ? _c("div", {
                staticClass: "modal__window__content",
                domProps: { innerHTML: _vm._s(_vm.modalContent) },
              })
            : _c("div", { staticClass: "modal__window__content loading" }, [
                _c("div", { staticClass: "loading-spinner" }),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Currency")]),
        _vm._v(" "),
        _c("th", [_vm._v("NAV")]),
        _vm._v(" "),
        _c("th", [_vm._v("% change")]),
        _vm._v(" "),
        _c("th", [_vm._v("Valuation Date")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }