<template>
    <div class="data-cell-panel">
        <h4 v-if="title">{{ title }}</h4>
        <div class="data-cells">
          <DataCell v-for="(cell, index) in cells" :key="index" :title="cell.title" :value="cell.value ? new String(cell.value) : ''" />
        </div>
    </div>
</template>

<script>

  import DataCell from './data-cell.vue';

  export default {
    props: {
      title: {
        type: String        
      },
      cells: {
        type: Array
      }
    },
    components: {
      DataCell
    }
  };
</script>