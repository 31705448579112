import Vue from '../vue-config'
import Company from '../components/company.vue';

if (document.getElementById('company-vm')) {

    var vm = new Vue({
        el: '#company-vm',
        components: {
            Company
        },
        // data: {
        // },
        // computed: {
        // },
        // watch: {
        // },
        // methods: {
        // },
        // mounted() {
        // }
    })
}