var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "security-details-stats-data" },
    [
      _vm.stats && _vm.stats.CloseBid
        ? _c("DataCellPanel", {
            attrs: { title: "Price Data", cells: _vm.priceData },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.stats && _vm.showTrade
        ? _c("DataCellPanel", {
            attrs: { title: "Trade Data", cells: _vm.tradeData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }