<template>
    <div class="modal" :class="(open ? ' open' : '')" v-show="open">
        <div class="modal__window">
            <div class="modal__close" @click="Close"></div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },
    data () {
      return {

      }
    },
    watch: {
        open(newVal, oldVal) {
            if (newVal && !oldVal) {
                // fix scroll
                const scrollY = 0 - window.scrollY;
                document.body.style.position = 'fixed';
                document.body.style.inset = `${scrollY}px 0 0 0`;

            } else if (!newVal && oldVal) {

                // replace scroll
                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.inset = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            }
        }
    },
    methods: {
        documentPointerEvent(event) {

            // ignore if click was within modal
            if (this.windowNode.contains(event.target)) {
                return;
            }

            this.Close();
        },
        documentOnKeyUp(event) {
            if(event.key === "Escape") {
                this.Close();
            }
        },
        Close() {
            this.$emit("onClose");
        }
    },
    mounted() {
        this.htmlNode = document.getElementsByTagName('html')[0];

        let windowNodes = this.$el.getElementsByClassName('modal__window');
        if (windowNodes.length > 0) this.windowNode = windowNodes[0];

        if (this.windowNode) {
            document.addEventListener('mousedown', this.documentPointerEvent, false);
            document.addEventListener('keyup', this.documentOnKeyUp);
        }
    },
    beforeUnmount() {
        if (this.windowNode) {
            document.removeEventListener('mousedown', this.documentPointerEvent, false);
            document.removeEventListener('keyup', this.documentOnKeyUp);
        }
    }
  };
</script>