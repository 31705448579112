<template>
    <div class="article securities">
        <div class="article-meta">
            <div class="article-meta-container">
                <div v-if="security" class="security">

                    <p class="subheader"><a href="/market/securities">Listed Securities</a></p>
                    <h1>{{ security.CompanyName }}</h1>

                    <div class="field-list" v-if="hasStats && security.WillTrade">
                        <div class="field-list-field">
                            <label>Today's Price Data</label>
                            <p>Price data up to 15 min delayed</p>
                        </div>
                        <div class="field-list-field col">
                            <label>Bid:</label>
                            <output v-if="stats.BestBid">{{ stats.CurrencyCode }} {{ stats.BestBid}}</output>
                            <output v-else-if="stats.CloseBid">{{ stats.CurrencyCode }} {{ stats.CloseBid}}</output>
                        </div>
                        <div class="field-list-field col">
                            <label>Ask:</label>
                            <output v-if="stats.BestAsk">{{ stats.CurrencyCode }} {{ stats.BestAsk}}</output>
                            <output v-else-if="stats.CloseAsk">{{ stats.CurrencyCode }} {{ stats.CloseAsk}}</output>
                        </div>
                        <div class="field-list-field col">
                            <label>Mid:</label>
                            <output v-if="stats.BestMid">{{ stats.CurrencyCode }} {{ stats.BestMid}}</output>
                            <output v-else-if="stats.CloseMid">{{ stats.CurrencyCode }} {{ stats.CloseMid}}</output>
                        </div>
                    </div>

                    <hr v-if="hasStats && security.WillTrade" />

                    <div class="field-list col">
                        <div class="field-list-field">
                            <label>Mnemonic:</label>
                            <output>{{ security.Mnemonic }}</output>
                        </div>
                        <div class="field-list-field">
                            <label>Security Type:</label>
                            <output>{{ security.SecurityTypeName }}</output>
                        </div>
                        <div class="field-list-field">
                            <label>Base Currency:</label>
                            <output>{{ security.CurrencyCode }}</output>
                        </div>
                        <div class="field-list-field">
                            <label>Security Class:</label>
                            <output>{{ security.SecurityClassName }}</output>
                        </div>
                        <div class="field-list-field" v-if="security.MarketMakerId">
                            <label>Market Maker:</label>
                            <output><a :href="`/members/current-members/${security.MarketMakerId}`">{{security.MarketMakerName}}</a></output>
                        </div>
                    </div>

                    <hr />

                    <div class="field-list col">
                        <div class="field-list-field">
                            <label>ISIN:</label>
                            <output>{{ security.ISIN }}</output>
                        </div>
                        <div class="field-list-field">
                            <label>Date Listed:</label>
                            <output>{{ format(security.ListedDate, "dd-MM-yyyy") }}</output>
                        </div>
                        <div class="field-list-field">
                            <label>Domicile:</label>
                            <output>{{ security.Domicile }}</output>
                        </div>
                        <div class="field-list-field">
                            <label>{{ AgentOrSponsorLabel}}:</label>
                            <output><a v-if="AgentOrSponsor" :href="AgentOrSponsorUrl">{{ AgentOrSponsor }}</a></output>
                        </div>
                        <div class="field-list-field">
                            <label>Category:</label>
                            <output>{{ security.CompanyCategory }}</output>
                        </div>
                        <div class="field-list-field">
                            <label>Issuer:</label>
                            <output><a v-if="security.IssuerId" :href="IssuerUrl">{{ security.IssuerName }}</a></output>
                        </div>
                        <div v-if="security.IsTiseSustainable" class="field-list-field">
                            <label>Segment:</label>
                            <output>TISE SUSTAINABLE</output>
                        </div>
                    </div>

                    <hr />

                    <div class="field-list" v-if="security.Address || security.City">
                        <div class="field-list-field">
                            <label>Company Address:</label>
                            <br />
                            <output>
                                <span v-if="security.Address" v-html="HandleNewlines(security.Address) + '<br />'"></span>
                                <template v-if="security.Postcode">{{ security.Postcode }}<br /></template>
                                <template v-if="security.City">{{ security.City }}<br /></template>
                                <template v-if="security.Domicile">{{ security.Domicile }}</template>
                            </output>
                        </div>
                    </div>

                    <hr v-if="security.Address || security.City" />

                </div>
                <div v-else class="skeleton">
                    <p class="subheader">Listed Securities</p>
                    <h2></h2>
                    <h1></h1>
                    <p></p>
                    <hr />
                    <p class="long"></p>
                    <hr />
                    <p></p>
                    <p class="long"></p>
                    <hr />
                </div>
            </div>
        </div>
        <div class="article-content">
            <SecurityDetails :security="security" :stats="stats" />
        </div>
    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    import SecurityDetails from './security-details.vue';

    export default {
        props: {
            theme: String,
            id: {
                type: String,
                required: true
            }
        },
        components: {
            SecurityDetails
        },
        data() {
             return {
                 baseUrl: '/market/securities',
                 sponsorUrl: '/members/current-members',
                 companiesUrl: '/market/companies',
                 securityUrl: `/api/Securities/${this.id}`,
                 statsUrl: `/umbraco/surface/ProxyApi/PriceStatistic/${this.id}`,
                 security: null,
                 stats: null,
                 format: format
             };
        },
        computed: {
            AgentOrSponsorLabel() {
                if (this.security.SecurityTypeId == 10 ||
                    this.security.SecurityTypeId == 25 ||
                    this.security.SecurityTypeId == 60) {
                    return "Listing Agent";
                }
                return "Sponsor";
            },
            AgentOrSponsor() {
                return this.security.Sponsor;
            },
            AgentOrSponsorUrl() {
                return `${this.sponsorUrl}/${this.security.SponsorId}`;
            },
            eventsUrl() {
                return `/umbraco/surface/ProxyApi/CompanyNews/${this.id}`;
            },
            hasStats() {
                return this.stats && (this.stats.CloseBid || this.stats.BestBid);
            },
            IssuerUrl() {
                return `${this.companiesUrl}/${this.security.IssuerId}`;
            }
        },
        methods: {
            HandleNewlines(value) {
                return value ? value.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
            },
            LoadData() {
                fetch(this.securityUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                }).then((response) => {
                    response.json().then((data) => {
                        this.security = data;
                        if (this.security.ListedDate) this.security.ListedDate = parseISO(this.security.ListedDate);
                        if (this.security.DelistedDate) this.security.DelistedDate = parseISO(this.security.DelistedDate);
                    });
                });
            },
            LoadStatsData() {
                fetch(this.statsUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                })
                .then(response => response.json())
                .then((data) => {
                    this.stats = data;
                });
            },
        },
        beforeMount() {
            this.LoadData();
            this.LoadStatsData();
        }
    };
</script>