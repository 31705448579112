var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sustainable-securities table-scrollable-container" },
    [
      _vm.securities
        ? _c("table", { staticClass: "data sustainable-securities-table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.securities, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", { staticClass: "nowrap-xl" }, [
                    _c("a", { attrs: { href: _vm.SecurityUrl(item) } }, [
                      _vm._v(_vm._s(item.CompanyName)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.ISIN))]),
                  _vm._v(" "),
                  _c("td", { staticClass: "nowrap" }, [
                    _vm._v(_vm._s(item.ListingDate)),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(Number(item.Nominal).toLocaleString())),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.CurrencyCode))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    _vm._l(item.Credentials, function (cred, credIndex) {
                      return _c("div", { key: credIndex }, [
                        _vm._v(_vm._s(cred)),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c("a", { attrs: { href: item.Url } }, [_vm._v("Link")]),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _c("div", { staticClass: "loading-spinner" }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Issuer")]),
        _vm._v(" "),
        _c("th", [_vm._v("ISIN")]),
        _vm._v(" "),
        _c("th", [_vm._v("Listing Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nominal")]),
        _vm._v(" "),
        _c("th", [_vm._v("Currency")]),
        _vm._v(" "),
        _c("th", [_vm._v("Credentials")]),
        _vm._v(" "),
        _c("th", [_vm._v("Information")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }