import Vue from '../vue-config'
import Marketclosingprices from '../components/market-update/closing-prices.vue';
import Marketrecenttrades from '../components/market-update/recent-trades.vue';
import Tab from '../components/utils/tab.vue';
import Tabs from '../components/utils/tabs.vue';

if (document.getElementById('marketupdate-vm')) {

    var vm = new Vue({
        el: '#marketupdate-vm',
        components: {
            Marketclosingprices,
            Marketrecenttrades,
            Tabs,
            Tab
        },
        // data: {
        // },
        // computed: {
        // },
        // watch: {
        // },
        // methods: {
        // },
        // mounted() {
        // }
    })
}