var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "article securities" }, [
    _c("div", { staticClass: "article-meta" }, [
      _c(
        "div",
        { staticClass: "article-meta-container" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _vm.description
            ? _c("p", {}, [_vm._v(_vm._s(_vm.description))])
            : _vm._e(),
          _vm._v(" "),
          _vm.renderSecuritiesFilter
            ? _c("SecuritiesFilter", {
                key: _vm.renderSecuritiesFilterKey,
                attrs: {
                  searchTerm: _vm.searchTerm,
                  securityType: _vm.securityType,
                  domicile: _vm.domicile,
                  currency: _vm.currency,
                },
                on: { filterUpdate: _vm.filterUpdate },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "article-content" },
      [
        _c("SecuritiesDataList", {
          attrs: {
            emptyMessage: "No securities were found.",
            theme: _vm.theme,
            pageNo: _vm.pageNo,
            securities: _vm.securities,
            totalSecurities: _vm.totalSecurities,
            pages: _vm.pages,
            exportFilter: _vm.Filter,
          },
          on: { changePage: _vm.UpdatePage },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }