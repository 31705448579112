import Vue from '../vue-config'
import Priceticker from '../components/price-ticker.vue';

if (document.getElementById('price-ticker-vm')) {

    var vm = new Vue({
        el: '#price-ticker-vm',
        components: {
            Priceticker
        }
    })
}