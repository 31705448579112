var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "securities sustainable-securities-embed" },
    [
      _c(
        "tabs",
        { ref: "tabs", staticClass: "full-width" },
        [
          _c(
            "tab",
            { attrs: { title: "Securities" } },
            [
              _c("p", [
                _vm._v("A list of securities admitted to TISE Sustainable."),
              ]),
              _vm._v(" "),
              _c("SustainableSecurities"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "tab",
            { attrs: { title: "Issuers" } },
            [
              _c("p", [
                _vm._v("A list of issuers admitted to TISE Sustainable."),
              ]),
              _vm._v(" "),
              _c("SustainableIssuers"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }