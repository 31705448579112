<template>
    <ul class="pagination" v-if="pages > 1">
        <li v-for="button in buttons" :key="button.key">
          <button :class="{
            'active' : (button.key == current),
            'link' : button.link,
          }" @click="UpdatePage(button)">{{ button.text }}</button>
        </li>
    </ul>
</template>

<script>

    export default {
    props: {
      pages: {
        type: Number,
        required: true
      },
      current: {
        type: Number,
        required: true
      },
      showMaximum: {
        type: Number,
        default: 5
      },
      showPrevious: {
        type: Boolean,
        default: false
      },
      showNext: {
        type: Boolean,
        default: false
      },
      previousText: {
        type: String,
        default: "«"
      },
      nextText: {
        type: String,
        default: "»"
      },
      showSkipped: {
        type: Boolean,
        default: true
      },
      skippedPagesText: {
        type: String,
        default: "…"
      }
    },
    computed: {
      buttons() {

        // start with just standard page nos
        let buttons = Array.from(Array(this.pages).keys()).map((index) => {
          return {
            key: index + 1,
            text: index + 1,
            link: (index + 1 == this.current) ? false : index + 1
          };
        });

        let skippedButton = (key) => {
          return {
            key: key,
            link: false,
            text: this.skippedPagesText
          };
        }

        // filter if we have more than we want to show
        if (this.pages > this.showMaximum) {

          let halfWindow = Math.floor(this.showMaximum / 2);

          let firstPortion = [];
          let middlePortion = [];
          let lastPortion = [];

          if (this.current <= halfWindow) {
            // if current is near the start then show single end page
            firstPortion = buttons.slice(0, this.showMaximum - 1);
            middlePortion = [skippedButton('skip')];
            lastPortion = buttons.slice(-1);
            buttons = firstPortion.concat(middlePortion).concat(lastPortion);

          } else if (this.current > this.pages - halfWindow) {
            // if current is near the end then show single start page
            firstPortion = buttons.slice(0, 1);
            middlePortion = [skippedButton('skip')];
            lastPortion = buttons.slice(0 - (this.showMaximum - 1));
            buttons = firstPortion.concat(middlePortion).concat(lastPortion);

          } else {
            // otherwise show single start+end plus range either side of current
            halfWindow = Math.floor((this.showMaximum - 2) / 2);
            firstPortion = buttons.slice(0, 1);

            let middleFrom = this.current - (halfWindow+1);
            let middleTo = this.current + halfWindow;
            middlePortion = buttons.slice(middleFrom, middleTo);
            if (middleFrom >= 2) middlePortion.unshift(skippedButton('skip1'));
            if (middleTo < (this.pages - 1)) middlePortion.push(skippedButton('skip2'));
            lastPortion = buttons.slice(-1);
            buttons = firstPortion.concat(middlePortion).concat(lastPortion);
          }
        }

        // add previous and next (IF NEEDED)
        if (this.showPrevious) {
          buttons.unshift({
              key: 'prev',
              link: (this.current > 1) ? this.current - 1 : false,
              text: this.previousText
            });
        }
        if (this.showNext) {
          buttons.push({
              key: 'next',
              link: (this.current < this.pages) ? this.current + 1 : false,
              text: this.nextText
            });
        }

        return buttons;
      }
    },
    methods: {
      UpdatePage(button) {
        if (button.link) {
          this.$emit("updatePage", button.link);
        }
      }
    }
  };
</script>