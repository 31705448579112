var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "security-details-recent-trades security-details-section" },
    [
      _c("h4", [_vm._v("Recent Trades")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Delayed by 20 minutes. Up to 25 of the latest trades shown."),
      ]),
      _vm._v(" "),
      _vm.hasRecentTrades
        ? _c("table", { staticClass: "data security-trades-table" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Price (" + _vm._s(_vm.currencyCode) + ")")]),
                _vm._v(" "),
                _c("th", [_vm._v("Size")]),
                _vm._v(" "),
                _c("th", [_vm._v("Currency")]),
                _vm._v(" "),
                _c("th", [_vm._v("Time/Date")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.recentTrades, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_c("strong", [_vm._v(_vm._s(item.Price))])]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.Size))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.CurrencyCode))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.TradeDate))]),
                ])
              }),
              0
            ),
          ])
        : _c("div", { staticClass: "loading-spinner" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }