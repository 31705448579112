var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "article securities" }, [
    _c("div", { staticClass: "article-meta" }, [
      _c("div", { staticClass: "article-meta-container" }, [
        _vm.security
          ? _c("div", { staticClass: "security" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("h1", [_vm._v(_vm._s(_vm.security.CompanyName))]),
              _vm._v(" "),
              _vm.hasStats && _vm.security.WillTrade
                ? _c("div", { staticClass: "field-list" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "field-list-field col" }, [
                      _c("label", [_vm._v("Bid:")]),
                      _vm._v(" "),
                      _vm.stats.BestBid
                        ? _c("output", [
                            _vm._v(
                              _vm._s(_vm.stats.CurrencyCode) +
                                " " +
                                _vm._s(_vm.stats.BestBid)
                            ),
                          ])
                        : _vm.stats.CloseBid
                        ? _c("output", [
                            _vm._v(
                              _vm._s(_vm.stats.CurrencyCode) +
                                " " +
                                _vm._s(_vm.stats.CloseBid)
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field-list-field col" }, [
                      _c("label", [_vm._v("Ask:")]),
                      _vm._v(" "),
                      _vm.stats.BestAsk
                        ? _c("output", [
                            _vm._v(
                              _vm._s(_vm.stats.CurrencyCode) +
                                " " +
                                _vm._s(_vm.stats.BestAsk)
                            ),
                          ])
                        : _vm.stats.CloseAsk
                        ? _c("output", [
                            _vm._v(
                              _vm._s(_vm.stats.CurrencyCode) +
                                " " +
                                _vm._s(_vm.stats.CloseAsk)
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field-list-field col" }, [
                      _c("label", [_vm._v("Mid:")]),
                      _vm._v(" "),
                      _vm.stats.BestMid
                        ? _c("output", [
                            _vm._v(
                              _vm._s(_vm.stats.CurrencyCode) +
                                " " +
                                _vm._s(_vm.stats.BestMid)
                            ),
                          ])
                        : _vm.stats.CloseMid
                        ? _c("output", [
                            _vm._v(
                              _vm._s(_vm.stats.CurrencyCode) +
                                " " +
                                _vm._s(_vm.stats.CloseMid)
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasStats && _vm.security.WillTrade ? _c("hr") : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "field-list col" }, [
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v("Mnemonic:")]),
                  _vm._v(" "),
                  _c("output", [_vm._v(_vm._s(_vm.security.Mnemonic))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v("Security Type:")]),
                  _vm._v(" "),
                  _c("output", [_vm._v(_vm._s(_vm.security.SecurityTypeName))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v("Base Currency:")]),
                  _vm._v(" "),
                  _c("output", [_vm._v(_vm._s(_vm.security.CurrencyCode))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v("Security Class:")]),
                  _vm._v(" "),
                  _c("output", [
                    _vm._v(_vm._s(_vm.security.SecurityClassName)),
                  ]),
                ]),
                _vm._v(" "),
                _vm.security.MarketMakerId
                  ? _c("div", { staticClass: "field-list-field" }, [
                      _c("label", [_vm._v("Market Maker:")]),
                      _vm._v(" "),
                      _c("output", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/members/current-members/${_vm.security.MarketMakerId}`,
                            },
                          },
                          [_vm._v(_vm._s(_vm.security.MarketMakerName))]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "field-list col" }, [
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v("ISIN:")]),
                  _vm._v(" "),
                  _c("output", [_vm._v(_vm._s(_vm.security.ISIN))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v("Date Listed:")]),
                  _vm._v(" "),
                  _c("output", [
                    _vm._v(
                      _vm._s(_vm.format(_vm.security.ListedDate, "dd-MM-yyyy"))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v("Domicile:")]),
                  _vm._v(" "),
                  _c("output", [_vm._v(_vm._s(_vm.security.Domicile))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v(_vm._s(_vm.AgentOrSponsorLabel) + ":")]),
                  _vm._v(" "),
                  _c("output", [
                    _vm.AgentOrSponsor
                      ? _c("a", { attrs: { href: _vm.AgentOrSponsorUrl } }, [
                          _vm._v(_vm._s(_vm.AgentOrSponsor)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v("Category:")]),
                  _vm._v(" "),
                  _c("output", [_vm._v(_vm._s(_vm.security.CompanyCategory))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field-list-field" }, [
                  _c("label", [_vm._v("Issuer:")]),
                  _vm._v(" "),
                  _c("output", [
                    _vm.security.IssuerId
                      ? _c("a", { attrs: { href: _vm.IssuerUrl } }, [
                          _vm._v(_vm._s(_vm.security.IssuerName)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _vm.security.IsTiseSustainable
                  ? _c("div", { staticClass: "field-list-field" }, [
                      _c("label", [_vm._v("Segment:")]),
                      _vm._v(" "),
                      _c("output", [_vm._v("TISE SUSTAINABLE")]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.security.Address || _vm.security.City
                ? _c("div", { staticClass: "field-list" }, [
                    _c("div", { staticClass: "field-list-field" }, [
                      _c("label", [_vm._v("Company Address:")]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "output",
                        [
                          _vm.security.Address
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.HandleNewlines(_vm.security.Address) +
                                      "<br />"
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.security.Postcode
                            ? [_vm._v(_vm._s(_vm.security.Postcode)), _c("br")]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.security.City
                            ? [_vm._v(_vm._s(_vm.security.City)), _c("br")]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.security.Domicile
                            ? [_vm._v(_vm._s(_vm.security.Domicile))]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.security.Address || _vm.security.City ? _c("hr") : _vm._e(),
            ])
          : _c("div", { staticClass: "skeleton" }, [
              _c("p", { staticClass: "subheader" }, [
                _vm._v("Listed Securities"),
              ]),
              _vm._v(" "),
              _c("h2"),
              _vm._v(" "),
              _c("h1"),
              _vm._v(" "),
              _c("p"),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", { staticClass: "long" }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p"),
              _vm._v(" "),
              _c("p", { staticClass: "long" }),
              _vm._v(" "),
              _c("hr"),
            ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "article-content" },
      [
        _c("SecurityDetails", {
          attrs: { security: _vm.security, stats: _vm.stats },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "subheader" }, [
      _c("a", { attrs: { href: "/market/securities" } }, [
        _vm._v("Listed Securities"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "field-list-field" }, [
      _c("label", [_vm._v("Today's Price Data")]),
      _vm._v(" "),
      _c("p", [_vm._v("Price data up to 15 min delayed")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }