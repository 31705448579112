<template>
    <div class="security-details-auction">

        <div class="field-list">
            <div class="field-list-field">
                <div class="field-list-field col">
                    <label><h4>Indicative price:</h4></label>
                    <output v-if="auction.indicative && Number.parseFloat(auction.indicative) > 0"><h4>{{ currency }} {{Number.parseFloat(auction.indicative).toFixed(2) }}</h4></output>
                </div>
                <div class="field-list-field col">
                    <label>Next auction date:</label>
                    <output>{{ DisplayNextAuction }}</output>
                </div>
                <div class="field-list-field col">
                    <label>Previous auction date:</label>
                    <output>{{ DisplayPrevAuction }}</output>
                </div>
                <div class="field-list-field col">
                    <label>Updated:</label>
                    <output>{{ updateAt }}</output>
                </div>
            </div>
        </div>

        <div class="security-details-auction-orders">
            <div v-if="hasBuyOrders" class="security-details-auction-orders-col">
                <h4>Buy orders</h4>
                <table class="data security-navs-table">
                    <thead>
                        <tr class="alternative-color">
                            <th>Volume</th>
                            <th>Price ({{ currency }})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in auction.buy.orders" :key="index">
                            <td>{{item.quantity}}</td>
                            <td>{{Number.parseFloat(item.price).toFixed(2)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="hasSellOrders" class="security-details-auction-orders-col">
                <h4>Sell orders</h4>
                <table class="data security-navs-table">
                    <thead>
                        <tr class="alternative-color">
                            <th>Price ({{ currency }})</th>
                            <th>Volume</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in auction.sell.orders" :key="index">
                            <td>{{Number.parseFloat(item.price).toFixed(2)}}</td>
                            <td>{{item.quantity}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    export default {
        props: {
            theme: String,
            auction: {
                type: Object,
                required: true
            },
            currency: {
                type: String,
                required: false,
                default: ""
            }
        },
        components: {

        },
        data() {
             return {
             };
        },
        computed: {
            updateAt() {
                if (this.auction && this.auction.updated) {
                    return format(new Date(this.auction.updated * 1000), "dd-MM-yyyy HH:mm");
                }
                return "";
            },
            DisplayNextAuction() {
                if (this.auction && this.auction.auction && this.auction.auction.next) {
                    return format(parseISO(this.auction.auction.next), "dd-MM-yyyy HH:mm");
                }
                return "";
            },
            DisplayPrevAuction() {
                if (this.auction && this.auction.auction && this.auction.auction.prev) {
                    return format(parseISO(this.auction.auction.prev), "dd-MM-yyyy HH:mm");
                }
                return "";
            },
            hasBuyOrders() {
                return (this.auction && this.auction.buy && this.auction.buy.orders);
            },
            hasSellOrders() {
                return (this.auction && this.auction.sell && this.auction.sell.orders);
            }
        },
        methods: {
        }
    };
</script>