import Vue from '../vue-config'
import Securitiesdatalist from '../components/securities-datalist.vue';

if (document.getElementById('search-vm')) {
    var vm = new Vue({
        el: '#search-vm',
        data: {
            searching: false,
            query: querystring,
            queried: '',
            results: [],
            securities: []
        },
        components: {
            Securitiesdatalist
        },
        computed: {
            searchTitle() {
                //Results for �RH�
                if (this.searching) {
                    return 'Searching...'
                }
                if (this.queried.length < 3) {
                    return 'Please enter a search term'
                }
                if (!this.results.length && !this.securities.length) {
                    return 'No results found'
                }                

                return 'Your results for &lsquo;' + this.queried + '&rsquo;'
            },
            searchSubtitle() {
                if (this.queried.length >= 3 && (this.results.length > 0 || this.securities.length > 0)) {
                    return this.results.length + ' website ' + (this.results.length == 1 ? ' result' : ' results') + ', ' + this.securities.length + (this.securities.length == 1 ? ' security' : ' securities')
                }

                return '';
            }
        },
        watch: {
        },
        methods: {
            doSearch() {
                var vm = this
                vm.queried = vm.query
                if (vm.query.length >= 3) {
                    vm.searching = true
                    fetch('/umbraco/api/search/search', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ query: vm.query })
                    }).then((response) => {
                        response.json().then((data) => {
                            vm.results = data
                        })
                    })
                    fetch('/api/Securities', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            Page: 1,
                            ItemsPerPage: 10,
                            Terms: vm.query
                        })
                    }).then((response) => {
                        response.json().then((data) => {
                            this.securities = data.Securities;
                        });
                        vm.searching = false
                    });
                } else {
                    this.results = [];
                    this.securities = [];
                }
            }
        },
        mounted() {
            var vm = this;
            vm.doSearch();
        }
    })
}