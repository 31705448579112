<template>
    <div class="security-details-recent-trades security-details-section">
        <h4>Recent Trades</h4>
        <p>Delayed by 20 minutes. Up to 25 of the latest trades shown.</p>

        <table v-if="tradesWithFormatting && tradesWithFormatting.length > 0" class="data security-trades-table">
            <thead>
                <tr>
                    <th>Price ({{currency}})</th>
                    <th>Size</th>
                    <th>Currency</th>
                    <th>Time/Date</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in tradesWithFormatting" :key="index">
                    <template v-if="item.price && item.trade">
                    <td><strong>{{item.price.price}}</strong></td>
                    <td>{{item.trade.quantity}}</td>
                    <td>{{item.price.currency}}</td>
                    <td>{{item.PlacedDate}}</td>
                    </template>
                </tr>
            </tbody>
        </table>

        <div v-else class="loading-spinner"></div>
    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    export default {
        props: {
            trades: {
                type: Array,
                required: true
            },
        },
        components: {
        },
        data() {
             return {
             };
        },
        computed: {
            tradesWithFormatting() {
                if (!this.trades || this.trades.length == 0) return [];

                return this.trades.map((t) => {
                    return Object.assign(t, {
                        CreatedDate: t.dates && t.dates.created ? format(parseISO(t.dates.created), "dd-MM-yyyy HH:mm") : null,
                        PlacedDate: t.dates && t.dates.placed ? format(parseISO(t.dates.placed), "dd-MM-yyyy HH:mm") : null,
                    });
                });
            },
            currency() {
                return (this.trades.length > 0 && this.trades[0].price) ? this.trades[0].price.currency : ''
            }
        },
        methods: {

        },
        beforeMount() {

        }
    };
</script>