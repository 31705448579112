<template>
    <div v-if="security && loadedTradePrices" class="security-details">
        <tabs ref="tabs">
            <tab v-if="auction" title="Auction System">
                <Auction :theme="theme" :auction="auction" :currency="security.CurrencyCode" />
            </tab>
            <tab v-if="security.WillTrade && hasTradePrices" :enabled="security.WillTrade && hasTradePrices" title="Trades &amp; Stats">
                <chart v-if="chartOptions" ref="highChart" constructor-type="stockChart" :options="chartOptions"></chart>

                <RecentTrades :securityClassId="security.SecurityClassId" :currencyCode="security.CurrencyCode" />
                <StatsData :security="security" :stats="stats" />
            </tab>
            <tab v-if="security.HasNav && loadedTradePrices" :enabled="security.HasNav" title="NAVs">
                <Navs :securityClassId="security.SecurityClassId" :companyId="security.CompanyId" />
                <div class="security-details-section">
                    <StatsData v-if="security.WillTrade && loadedTradePrices && !hasTradePrices" :security="security" :stats="stats" />
                </div>
            </tab>
            <tab title="News">
                <News v-if="security && security.CompanyId" :companyId="security.CompanyId" />
            </tab>
            <tab :enabled="security.ReutersPage && security.ReutersPage.length > 0" title="Information">
                <pre>{{ security.ReutersPage }}</pre>
            </tab>
            <tab v-if="documents" title="Documents">
                <Documents :theme="theme" :documents="documents" />
            </tab>
        </tabs>
        <Modal :open="modalOpen" @onClose="modalOpen = false">
            <div v-if="modalContent" class="modal__window__content" v-html="modalContent" />
            <div v-else class="modal__window__content loading">
                <div class="loading-spinner"></div>
            </div>
        </Modal>
    </div>
    <div v-else class="skeleton">
        <ul class="tabs">
            <li class="active"></li>
            <li class=""></li>
            <li class=""></li>
        </ul>
        <div class="tab">
            <p class="img"></p>
            <p class="long"></p>
            <p class="long"></p>
        </div>
    </div>
</template>

<script>

    //import { ParseMicrosoftJsonDate } from '../utils.js';
    import { format, parseISO } from 'date-fns';
    import Tab from './utils/tab.vue';
    import Tabs from './utils/tabs.vue';
    import Modal from './utils/modal.vue';
    import RecentTrades from './security-details/recent-trades.vue';
    import Navs from './security-details/navs.vue';
    import News from './security-details/news.vue';
    import Auction from './security-details/auction.vue';
    import Documents from './security-details/documents.vue';
    import StatsData from './security-details/stats-data.vue';
    import Highcharts from 'highcharts';
    import { Chart } from 'highcharts-vue';
    import stockInit from 'highcharts/modules/stock';
    import debuggerInit from 'highcharts/modules/debugger';

    stockInit(Highcharts);
    debuggerInit(Highcharts);

    export default {
        props: {
            theme: String,
            security: {
                type: Object,
            },
            stats: {
                type: Object
            }
        },
        components: {
            Tab,
            Tabs,
            Chart,
            Modal,
            RecentTrades,
            StatsData,
            Navs,
            News,
            Auction,
            Documents
        },
        data() {
             return {
                 baseUrl: '/market/securities',
                 newsItemBaseUrl: '/umbraco/surface/proxyapi/NewsItem?id=',
                 newsItemBaseViewUrl: '/market/news/',
                 recentTrades: null,
                 tradePrices: null,
                 loadedTradePrices: false,
                 events: null,
                 modalOpen: false,
                 modalContent: null,
                 auction: null,
                 documents: null
             };
        },
        watch: {
            security(newVal, oldVal) {

                if (newVal != null) {
                    this.LoadData();
                }
            }
        },
        computed: {
            recentTradesUrl() {
                return `/umbraco/surface/ProxyApi/RecentTrades/${this.security.SecurityClassId}?PageNo=1&PageSize=25`;
            },
            tradesUrl() {
                return `/api/securities/${this.security.SecurityClassId}/trades/${this.security.CompanyId}`;
            },
            eventsUrl() {
                return `/umbraco/surface/ProxyApi/CompanyNews/${this.security.CompanyId}`;
            },
            auctionUrl() {
                return `/api/auction/${this.security.Mnemonic}`;
            },
            documentsUrl() {
                return `/api/documents/${this.security.Mnemonic}`;
            },
            hasTradePrices() {
                return this.tradePrices && this.tradePrices.length > 0;
            },
            hasRecentTrades() {
                return !!this.recentTrades;
            },
            chartOptions() {
                if (!this.hasTradePrices) return null;

                return {
                    rangeSelector: {
                        selected: 5
                    },
                    xAxis: {
                        type: 'datetime',
                        minRange: 24 * 3600 * 1000,
                        ordinal: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    series: [
                        {
                            id: "trades",
                            name: this.security.Mnemonic,
                            data: this.tradePrices,
                            tooltip: {
                                valueDecimals: 2
                            }
                        },
                        {
                            type: 'flags',
                            data: this.events,
                            //onSeries: 'trades', // plot along line, or along axis?
                            events: {
                                click: (e) => {
                                    this.OpenModal(this.newsItemBaseViewUrl + e.point.id);
                                }
                            },
                            shape: 'marker',
                            lineColor: '#62B0D3',
                            fillColor: '#65B2D6',
                            width: 12,
                            height: 9,
                            y: -24
                        }
                ]
                };
            }
        },
        methods: {
            OpenModal(contentUrl) {
                this.modalContent = null;
                this.modalOpen = true;

                // load the content
                fetch(contentUrl).then((response) => {
                    response.text().then((data) => {
                        this.modalContent = data;
                    });
                });
            },
            LoadData() {

                // always try and load auction data, expect null back if there isn't any
                const auctionPromise = fetch(this.auctionUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                auctionPromise.then((response) => {
                    response.json().then((data) => {
                        // if (data == null || (data.auction.next == "" && data.auction.prev == "")) {
                        if (data == null) {
                            this.auction = null;
                        } else {
                            this.auction = data; // may be null
                        }
                    });
                });

                // always try and load documents data, expect null back if there isn't any
                const documentsPromise = fetch(this.documentsUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                documentsPromise.then((response) => {
                    response.json().then((data) => {
                        this.documents = data; // may be null
                    });
                });

                // load trade data, if needed
                if(this.security.WillTrade) {
                    const tradesPromise = fetch(this.tradesUrl, {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    });

                    const eventsPromise = fetch(this.eventsUrl, {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    });

                    Promise.all([tradesPromise, eventsPromise]).then((dataArray) => {

                        const trades = dataArray[0];
                        const tradesJsonPromise = trades.json();

                        const events = dataArray[1];
                        const eventsJsonPromise = events.json();

                        Promise.all([tradesJsonPromise, eventsJsonPromise]).then((data) => {

                            if (data[0]) {
                                this.tradePrices = data[0].map((t) => {
                                    return [
                                        t.date ? parseInt(format(parseISO(t.date), "T")) : null,
                                        t.price
                                    ];
                                }).sort((a, b) => {return a[0] - b[0]; });
                            }

                            if (data[1]) {
                                const news = data[1].CompanyNews || data[1].News;
                                this.events = news.map((e) => {
                                    return {
                                        x: e.Published ? parseInt(format(parseISO(e.Published), "T")) : null,
                                        title: " ",
                                        text: e.Headline,
                                        id: e.Id
                                    };
                                }).sort((a, b) => {return a.x - b.x; });
                            }

                            // NOTE: trades & events sorted on server so no need to do here
                            // NEW NOTE: sorting here just in case, news now reverse sorteing broke this!

                            this.$nextTick(() => {
                                this.loadedTradePrices = true;
                                if (this.$refs.tabs) {
                                    this.$refs.tabs.selectTab(0);
                                }
                            });
                        });

                    });

                    // if (response.b_willtrade) {
                    //     vue.getTrades()
                    //     vue.getStats()
                    // }
                    // if (response.b_hasnav) {
                    //     vue.getNAVs()
                    // }
                    const recentTradesPromise = fetch(this.recentTradesUrl, {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    });
                    recentTradesPromise.
                        then(response => response.json()).
                        then((data) => {
                            this.recentTrades = data.map((t) => {
                                return Object.assign(t, {
                                    Published: t.Published ? format(parseISO(t.Published), "dd-MM-yyyy HH:mm") : null,
                                    TradeDate: t.TradeDate ? format(parseISO(t.TradeDate), "dd-MM-yyyy HH:mm") : null,
                                });
                            });

                    });

                } else {
                    // if not trading - set that they have been loaded
                    this.loadedTradePrices = true;
                }

            },
        }
    };
</script>