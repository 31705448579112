import Vue from '../vue-config'
import Securities from '../components/securities.vue';
import Securitiesembed from '../components/securities-embed.vue';
import Novaembed from '../components/nova-embed.vue';
import Sustainableembed from '../components/sustainable-embed.vue';
import Security from '../components/security.vue';

if (document.getElementById('securities-vm')) {

    var vm = new Vue({
        el: '#securities-vm',
        components: {
            Securitiesembed,
            Novaembed,
            Sustainableembed,
            Security,
            Securities
        },
        // data: {
        // },
        // computed: {
        // },
        // watch: {
        // },
        // methods: {
        // },
        // mounted() {
        // }
    })
}