<template>
    <div class="security-details-stats-data">

        <DataCellPanel v-if="stats && stats.CloseBid" title="Price Data" :cells="priceData" />
        <!-- <DataCellPanel v-if="stats && showDividend" title="Dividend Data" :cells="dividendData" /> -->
        <DataCellPanel v-if="stats && showTrade" title="Trade Data" :cells="tradeData" />

    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    import DataCellPanel from '../utils/data-cell-panel.vue';

    export default {
        props: {
            theme: String,
            security: {
                type: Object,
                required: false
            },
            stats: {
                type: Object,
                required: false
            }
        },
        data() {
             return {
                currenciesUrl: '/umbraco/surface/ProxyApi/currencies',
                currencies: [],
                currency: null,
             };
        },
        components: {
            DataCellPanel
        },
        computed: {
            currencyMajor() {
                if (this.currency == null) return "GBP";

                if (this.currency.MajorCode) {
                    return `${this.currency.MajorCode}`;
                }

                return `${this.currency.Code}`;
            },
            currencyDivisor() {
                if (this.currency == null) return 1;
                return this.currency.Divisor;
            },
            showDividend() {
                return this.stats && (this.stats.CloseBid ||
                 this.stats.Dividend ||
                 this.stats.ExDividendDate ||
                 this.stats.Yield ||
                 this.stats.XCodeType
                );
            },
            showTrade() {
                return this.stats && (this.stats.TradeTotal ||
                 this.stats.TotalVolume ||
                 this.stats.TotalTurn ||
                 this.stats.YearToDateVolume ||
                 this.stats.YearToDateTurnover
                );
            },
            tradeData() {
                if (this.showDividend && this.currency) {
                    return [
                        {title: "Total trades today", value: this.DisplayNumber(this.stats.TradeTotal)},
                        {title: "Total shares traded today", value: this.DisplayNumber(this.stats.TradeTotal)},
                        {title: "Total trade turnover today", value: this.DisplayCurrency(this.stats.TradeTotal)},
                        {title: "Year to date volume", value: this.DisplayNumber(this.stats.YearToDateVolume)},
                        {title: `Year to date turnover (${this.currencyMajor})`, value: this.DisplayCurrency(this.stats.YearToDateTurnover)}
                    ];
                } else {
                    return null;
                }
            },
            dividendData() {
                if (this.showDividend && this.currency) {
                    return [
                        {title: "Dividend", value: this.stats.Dividend},
                        {title: "Dividend date", value: (this.stats.DividendDate ? format(parseISO(this.stats.DividendDate), "dd-MM-yyyy") : null)},
                        {title: "X-Div", value: (this.stats.ExDividendDate ? format(parseISO(this.stats.ExDividendDate), "dd-MM-yyyy") : null)},
                        {title: "Yield", value: this.stats.Yield},
                        {title: "X-Flag", value: this.stats.XCodeType}
                    ];
                } else {
                    return null;
                }
            },
            priceData() {
                if (this.security && this.stats && this.currency) {
                    return [
                        {title: "Bid close", value: this.DisplayCurrency(this.stats.CloseBid)},
                        {title: "Ask close", value: this.stats.CloseAsk},
                        {title: "Mid close", value: this.stats.CloseMid},
                        {title: "Close date", value: (this.stats.CloseDate ? format(parseISO(this.stats.CloseDate), "dd-MM-yyyy") : null)},
                        {title: "Bid open", value: this.stats.OpenBid},
                        {title: "Ask open", value: this.stats.OpenAsk},
                        {title: "Mid open", value: this.stats.OpenMid},
                        {title: "Bid", value: this.stats.BestBid},
                        {title: "Ask", value: this.stats.BestAsk},
                        {title: "Mid", value: this.stats.BestMid},
                        {title: "Change", value: this.stats.MidChangePer},
                        {title: "Daily low", value: this.stats.BidLow},
                        {title: "Daily high", value: this.stats.AskHigh},
                        {title: "Net asset value", value: this.stats.NetAsset},
                        {title: "Net asset Date", value: (this.stats.NetAssetDate ? format(parseISO(this.stats.NetAssetDate), "dd-MM-yyyy") : null)},
                        {title: "Minimum Quotation Size", value: (this.stats.NormalMarketSize && this.security.MarketMakerId > 0) ? this.stats.NormalMarketSize : null},
                        {title: "VWap", value: this.stats.VWap},
                        {title: "Annual low", value: this.stats.AnnualLow},
                        {title: "Annual low date", value: (this.stats.AnnualLowDate ? format(parseISO(this.stats.AnnualLowDate), "dd-MM-yyyy") : null)},
                        {title: "Annual high", value: this.stats.AnnualLow},
                        {title: "Annual high date", value: (this.stats.AnnualHighDate ? format(parseISO(this.stats.AnnualHighDate), "dd-MM-yyyy") : null)},
                        {title: "52 week low", value: this.stats.FiftyTwoWeekLow},
                        {title: "52 week high", value: this.stats.FiftyTwoWeekHigh},
                        {title: `Market Cap (${this.currencyMajor})`, value: this.DisplayCurrency(this.stats.MarketCap)},
                        {title: "Market Cap date", value: (this.stats.MarketCapDate ? format(parseISO(this.stats.MarketCapDate), "dd-MM-yyyy") : null)},
                        {title: "Latest News date", value: (this.stats.NewsLatestDate ? format(parseISO(this.stats.NewsLatestDate), "dd-MM-yyyy") : null)}
                    ];
                } else {
                    return null;
                }
            }
        },
        methods: {
            DisplayCurrency(value) {
                if (!value) return 0;
                let val = value / this.currencyDivisor;
                 var formatter = new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: this.currencyMajor,
                    currencyDisplay: "code"
                });
                return formatter.format(val);
            },
            DisplayNumber(value) {
                if (!value) return 0;
                var formatter = new Intl.NumberFormat('en-GB');
                return formatter.format(value);
            },
            LoadData() {
                const currenciesPromise = fetch(this.currenciesUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                currenciesPromise.
                    then(response => response.json()).
                    then((data) => {
                        let ccy = data.filter((c) => {
                            return c.Code == this.stats.CurrencyCode;
                        });
                        if (ccy.length > 0) {
                            this.currency = ccy[0];
                        } else {
                            this.currency = {
                                "Id": 0,
                                "Code": "GBP",
                                "Description": "Uk Pounds",
                                "Divisor": 1,
                                "MajorCode": null
                            };
                        }
                });

            },
        },
        beforeMount() {
            this.LoadData();
        }
    };
</script>