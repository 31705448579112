<template>
    <div class="security-details-navs">

        <chart v-if="chartOptions" ref="highChart" constructor-type="stockChart" :options="chartOptions"></chart>

        <div v-if="navsLoaded" class="data security-details-section">
            <table class="data security-navs-table">
                <thead>
                    <tr>
                        <th>Currency</th>
                        <th>NAV</th>
                        <th>% change</th>
                        <th>Valuation Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in navs" :key="index">
                        <td>{{item.CurrencyCode}}</td>
                        <td>{{item.NAV}}</td>
                        <td>{{item.PercentageChange || item.PercentageChange === 0 ? Number.parseFloat(item.PercentageChange).toFixed(2) : '-'}}</td>
                        <td>{{item.NAVDate}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="loading-spinner"></div>

        <Modal :open="modalOpen" @onClose="modalOpen = false">
            <div v-if="modalContent" class="modal__window__content" v-html="modalContent" />
            <div v-else class="modal__window__content loading">
                <div class="loading-spinner"></div>
            </div>
        </Modal>
    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Modal from '../utils/modal.vue';
    import Highcharts from 'highcharts';
    import { Chart } from 'highcharts-vue';
    import stockInit from 'highcharts/modules/stock';
    import debuggerInit from 'highcharts/modules/debugger';

    stockInit(Highcharts);
    debuggerInit(Highcharts);

    export default {
        props: {
            theme: String,
            securityClassId: {
                type: Number,
                required: true
            },
            companyId: {
                type: Number,
                required: true
            }
        },
        components: {
            Chart,
            Modal
        },
        data() {
             return {
                 newsItemBaseUrl: '/umbraco/surface/proxyapi/NewsItem?id=',
                 newsItemBaseViewUrl: '/market/news/',
                 navs: null,
                 navsLoaded: false,
                 navsPrices: null,
                 events: null,
                 modalOpen: false,
                 modalContent: null
             };
        },
        computed: {
            navsUrl() {
                return `/umbraco/surface/ProxyApi/NavPrices/${this.securityClassId}`;
            },
            navsChartUrl() {
                return `/api/securities/${this.securityClassId}/navs/${this.companyId}`;
            },
            eventsUrl() {
                return `/umbraco/surface/ProxyApi/CompanyNews/${this.companyId}`;
            },
            hasNavs() {
                return !!this.navs;
            },
            hasNavChartData() {
                return !!this.navsPrices;
            },
            chartOptions() {
                if (!this.hasNavChartData) return null;

                return {
                    rangeSelector: {
                        selected: 5
                    },
                    xAxis: {
                        type: 'datetime',
                        minRange: 24 * 3600 * 1000,
                        ordinal: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    series: [
                        {
                            id: "navs",
                            name: "NAV", //this.security.Mnemonic,
                            data: this.navsPrices,
                            tooltip: {
                                valueDecimals: 2
                            }
                        },
                        {
                            type: 'flags',
                            data: this.events,
                            //onSeries: 'navs', // plot along line, or along axis?
                            events: {
                                click: (e) => {
                                    this.OpenModal(this.newsItemBaseViewUrl + e.point.id);
                                }
                            },
                            shape: 'marker',
                            lineColor: '#62B0D3',
                            fillColor: '#65B2D6',
                            width: 12,
                            height: 9,
                            y: -24
                        }
                ]
                };
            }
        },
        methods: {
            OpenModal(contentUrl) {
                this.modalContent = null;
                this.modalOpen = true;

                // load the content
                fetch(contentUrl).then((response) => {
                    response.text().then((data) => {
                        this.modalContent = data;
                    });
                });
            },
            LoadData() {
                // load data for table
                const navsPromise = fetch(this.navsUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                navsPromise.
                    then(response => response.json()).
                    then((data) => {
                        this.navs = data.map((t) => {
                            return Object.assign(t, {
                                NAVDate: t.NAVDate ? format(parseISO(t.NAVDate), "dd-MM-yyyy") : null,
                            });
                        });
                        this.navsLoaded = true;
                });

                // load chart data
                const navsChartPromise = fetch(this.navsChartUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                const eventsPromise = fetch(this.eventsUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                Promise.all([navsChartPromise, eventsPromise]).then((dataArray) => {

                    const navsData = dataArray[0];
                    const navsJsonPromise = navsData.json();

                    const events = dataArray[1];
                    const eventsJsonPromise = events.json();

                    Promise.all([navsJsonPromise, eventsJsonPromise]).then((data) => {

                        this.navsPrices = data[0].map((t) => {
                            return [
                                t.date ? parseInt(format(parseISO(t.date), "T")) : null,
                                t.value
                            ];
                        }).sort((a, b) => {return a[0] - b[0]; });;

                        const news = data[1].News || data[1].CompanyNews;
                        if (news) {
                            this.events = news.map((e) => {
                                return {
                                    x: e.Published ? parseInt(format(parseISO(e.Published), "T")) : null,
                                    title: " ",
                                    text: e.Headline,
                                    id: e.Id
                                };
                            }).sort((a, b) => {return a.x - b.x; });
                        }

                        // NOTE: trades & events sorted on server so no need to do here
                        // NEW NOTE: sorting here just in case, news now reverse sorteing broke this!
                    });

                });

            },
        },
        beforeMount() {
            this.LoadData();
        }
    };
</script>