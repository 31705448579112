<template>
    <div class="article securities">
        <div class="article-meta">
            <div class="article-meta-container">
                <h1>{{ title }}</h1>
                <p v-if="description" class="">{{ description }}</p>
                <SecuritiesFilter :key="renderSecuritiesFilterKey" v-if="renderSecuritiesFilter" @filterUpdate="filterUpdate" :searchTerm="searchTerm" :securityType="securityType" :domicile="domicile" :currency="currency" />
            </div>
        </div>
        <div class="article-content">
            <SecuritiesDataList emptyMessage="No securities were found." @changePage="UpdatePage" :theme="theme" :pageNo="pageNo" :securities="securities" :totalSecurities="totalSecurities" :pages="pages" :exportFilter="Filter" />
        </div>
    </div>
</template>

<script>
    import SecuritiesFilter from './securities-filter.vue';
    import SecuritiesDataList from './securities-datalist.vue';

    export default {
        props: {
            theme: String,
            title: {
                type: String,
                required: true
            },
            description: {
                type: String
            },
        },
        components: {
            SecuritiesFilter,
            SecuritiesDataList
        },
        data() {
             return {
                 baseUrl: '/market/securities',
                 securitiesUrl: '/api/Securities',
                 searchTerm: "",
                 securityType: 0,
                 domicile: 0,
                 currency: 0,
                 pageNo: 1,
                 pageSize: 16,
                 securities: [],
                 totalSecurities: 0,
                 pages: 0,
                 renderSecuritiesFilter: true,
                 renderSecuritiesFilterKey: "renderSecuritiesFilterKey"
             };
        },
        computed: {
            SearchQuery() {
                return {
                    Page: this.pageNo,
                    ItemsPerPage: this.pageSize,
                    Terms: this.searchTerm,
                    Domicile: this.domicile,
                    SecurityType: this.securityType,
                    Currency: this.currency
                }
            },
            Filter() {
                return {
                    Terms: this.searchTerm,
                    Domicile: this.domicile,
                    SecurityType: this.securityType,
                    Currency: this.currency
                }
            },
            pushstateData: {
                get() {
                    return {
                        Page: this.pageNo || 1,
                        Terms: this.searchTerm,
                        Domicile: this.domicile,
                        SecurityType: this.securityType,
                        Currency: this.currency
                    };
                },
                set(value) {
                    try {
                        if (value && value != "") {
                            let stateObj = JSON.parse(value);
                            if (stateObj) {

                                this.searchTerm = stateObj.Terms;
                                this.securityType = stateObj.SecurityType;
                                this.domicile = stateObj.Domicile;
                                this.currency = stateObj.Currency;
                                this.pageNo = stateObj.Page || 1;
                                this.UpdateSearch(false);

                                // force recreation of securities filter, this is awful but need to find
                                // a way to set props on v-model components (selects lower down the tree)
                                // after they have been created.
                                this.renderSecuritiesFilter = false;
                                this.$nextTick(() => {
                                    this.renderSecuritiesFilter = value;
                                    this.renderSecuritiesFilterKey = true;
                                });
                            }
                        }
                    } catch (e) {
                        console.warn("Pushstate data could not be parsed.");
                    }
                }
            }, // pushstateData
        },
        methods: {
            filterUpdate(filter) {
                this.searchTerm = filter.searchTerm;
                this.securityType = filter.securityType;
                this.domicile = filter.domicile;
                this.currency = filter.currency;
                this.pageNo = 1; // reset page on new search
                this.UpdateSearch();
            },
            UpdatePage(pageNo) {
                this.pageNo = pageNo;
                this.UpdateSearch();
            },
            popstate() {
                // back/forward handler
                this.pushstateData = this.getHash();
            },
            UpdateSearch(updateState) {

                // ensure we don't update pushstate FROM pushstate
                if (updateState === undefined) updateState = true;
                if (updateState) {
                    this.setHash(JSON.stringify(this.SearchQuery), false); // remember current state
                }

                fetch(this.securitiesUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.SearchQuery)
                }).then((response) => {
                    response.json().then((data) => {
                        this.securities = data.Securities;
                        this.totalSecurities = data.Total;
                        this.pages = data.TotalPages;
                    });
                });
            },
        },
        created() {
            // get hash and update state to reflect data
            let hash = this.getHash();
            this.pushstateData = hash;

            // also listen for popstate and do likewise
            window.addEventListener('popstate', this.popstate);
        },
        beforeUnmount() {
            // unregister our popstate listener
            window.removeEventListener('popstate', this.popstate);
        },
        beforeMount() {
            this.UpdateSearch();
        }
    };
</script>