var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "market-news-data-list" },
    [
      _c("div", { staticClass: "data-list" }, [
        _c(
          "div",
          { staticClass: "data-list-header" },
          [
            _vm._t("header", function () {
              return [
                _c("output", { attrs: { form: "market-news-search-filter" } }, [
                  _vm._v(_vm._s(_vm.totalNews) + " results"),
                ]),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "data-list-body" },
          _vm._l(_vm.news, function (item) {
            return _c("div", { key: item.Id, staticClass: "data-list-item" }, [
              _c(
                "div",
                { staticClass: "data-list-item-field data-list-item-col" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "market-news--company",
                      attrs: { href: _vm.CompanyUrl(item) },
                    },
                    [_c("strong", [_vm._v(_vm._s(item.CompanyName))])]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "market-news-mnemonic" }, [
                    _vm._v(_vm._s(item.Mnemonic)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "data-list-item-field market-news-headline" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.NewsItemUrl(item.Id) },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.OpenNews(item.Id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.Headline))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "data-list-item-field market-news-datetime" },
                [_vm._v(_vm._s(_vm.FormatDateTime(item.Published)))]
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "data-list-postscript" },
        [
          _c("Pagination", {
            attrs: {
              pages: _vm.pages,
              current: _vm.pageNo,
              showMaximum: 6,
              showPrevious: false,
              showNext: false,
            },
            on: { updatePage: _vm.UpdatePage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: { open: _vm.modalOpen },
          on: {
            onClose: function ($event) {
              _vm.modalOpen = false
            },
          },
        },
        [
          _vm.modalContent
            ? _c("div", {
                staticClass: "modal__window__content",
                domProps: { innerHTML: _vm._s(_vm.modalContent) },
              })
            : _c("div", { staticClass: "modal__window__content loading" }, [
                _c("div", { staticClass: "loading-spinner" }),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-list-header" }, [
      _c("div", { staticClass: "data-list-item" }, [
        _c("div", { staticClass: "data-list-item-field" }, [_vm._v("Company")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "data-list-item-field market-news-headline" },
          [_vm._v("Headline")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "data-list-item-field" }, [
          _vm._v("Date/Time"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }