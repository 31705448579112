import Vue from '../vue-config'
import Marketnews from '../components/market-news.vue';

if (document.getElementById('marketnews-vm')) {

    var vm = new Vue({
        el: '#marketnews-vm',
        components: {
            Marketnews
        },
        // data: {
        // },
        // computed: {
        // },
        // watch: {
        // },
        // methods: {
        // },
        // mounted() {
        // }
    })
}