<template>
    <div class="market-update market-update-closing-prices">

        <table v-if="hasData" class="data closing-prices-table">
            <thead>
                <tr>
                    <th>Company Name<br />
                    - Class Name (Mnemonic)</th>
                    <th>Currency</th>
                    <th>Bid</th>
                    <th>Ask</th>
                    <th>YTD Vol</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(priceKey, index) in Object.keys(closingPrices)">
                    <tr :key="'st' + index" class="table-body-header">
                        <th colspan="99">{{ SecurityTypeDescription(priceKey) }}</th>
                    </tr>
                    <template v-for="(price, priceIndex) in closingPrices[priceKey]">
                    <tr :key="'st' + index + 'p' + priceIndex">
                        <td><a :href="SecurityUrl(price)">{{price.CompanyName}}</a><br />
                        - {{price.ClassName}} ({{price.Mnemonic}})</td>
                        <td>{{price.CurrencyCode}}</td>
                        <td>{{price.CloseBid}}</td>
                        <td>{{price.CloseAsk}}</td>
                        <td>{{price.YearToDateVolume > 0 ? price.YearToDateVolume : ''}}</td>
                    </tr>
                    </template>
                </template>
            </tbody>
        </table>

        <div v-else class="loading-spinner"></div>
    </div>
</template>

<script>

    import { format, parseISO } from 'date-fns';
    export default {
        props: {
            theme: String
        },
        components: {
        },
        data() {
             return {
                 closingPrices: null,
                 securityTypes: null
             };
        },
        computed: {
            closingPricesUrl() {
                return `/api/closing-prices`;
            },
            hasData() {
                return this.closingPrices && this.securityTypes;
            }
        },
        methods: {
            SecurityUrl(price) {
                return `/market/securities/${price.SecurityClassId}`;
            },
            SecurityTypeDescription(id) {
                let secType = this.securityTypes.find(item => item.Id == id );
                if (secType) return secType.Description;
                return "";
            },
            LoadData() {

                const closingPricesPromise = fetch(this.closingPricesUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                closingPricesPromise.
                    then(response => response.json()).
                    then((data) => {

                        let prices = data.ClosingPrices;
                        let secTypes = data.SecurityTypes;

                        // format dates in data
                        // if (secTypes && prices) {
                        //     secTypes.forEach(secType => {
                        //         if (prices[secType]) {
                        //             prices[secType] = prices[secType].map(price => {
                        //                 return Object.assign(data, {
                        //                     CloseDate: price.CloseDate ? format(parseISO(price.CloseDate), "dd-MM-yyyy") : null,
                        //                     mCloseDate: price.mCloseDate ? format(parseISO(price.mCloseDate), "dd-MM-yyyy") : null,
                        //                 });
                        //             });
                        //         }
                        //     });
                        // }

                        // update data
                        this.closingPrices = prices;
                        this.securityTypes = secTypes;
                });

            },
        },
        beforeMount() {
            this.LoadData();
        }
    };
</script>