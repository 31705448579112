var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "security-details-documents" }, [
    _vm.documents && _vm.documents.length > 0
      ? _c("table", { staticClass: "data small-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.documents, function (document, index) {
              return _c("tr", { key: index }, [
                _c("td", [
                  _c("a", { attrs: { href: document.url } }, [
                    _c("strong", [_vm._v(_vm._s(document.name))]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("a", { attrs: { href: document.url } }, [
                    _vm._v(_vm._s(_vm.updatedAt(document))),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("a", { attrs: { href: document.url } }, [
                    _vm._v(_vm._s(_vm.createdAt(document))),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ])
      : _c("p", [_vm._v("No documents were found.")]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Document")]),
        _vm._v(" "),
        _c("th", [_vm._v("Updated")]),
        _vm._v(" "),
        _c("th", [_vm._v("Created")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }